import React, { Component, useEffect, useState } from 'react';
import withAuth from '../components/Login/withAuth';
import { confirmDelete, crudAlertControl, initDataTable } from "../Functions.js";
import ModalDelete from "../components/ModalDelete";
import LocationAlert from '../components/LocationAlert';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const Permissions = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [roles, setRoles] = useState([]);
    const [lastid, setLastid] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const getAllRoles = async () => {
        const roles = await import("../data/modules.js");
        const data = await roles.getRoles();
        const rolesArray = [];

        setLastid(data[data.length - 1].id);
        
        await Promise.all(data.map(role => {
            if(role.deleted === 0){
                rolesArray.push(role);
            }
        }))

        setRoles(rolesArray);
        initDataTable(); 
    }

    const addRole = async (newid, event) => {
        event.preventDefault();
        var rolename = event.target.rolename.value;

        if(rolename){
            const roles = await import("../data/modules.js");
            const data = await roles.addRoles(newid, rolename);

            if(data && data.length > 0){
                navigate('/manage/permissions/role/' + data[0].id);
            }
        }
    }

    const delRole = async (roleid, rolename) => {
        if(roleid){
            const roles = await import("../data/modules.js");
            const data = await roles.deleteRole(roleid, rolename);

            await getAllRoles();
            crudAlertControl('delete', data);
            modalHide();
        }
    }

    const modalShow = () => {
        setShowModal(true);
    }

    const modalHide = () => {
        setShowModal(false);
    }

    useEffect(() => {
        getAllRoles();
    }, [])

    const newid = (lastid + 1);
    console.log(lastid, newid);

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <div onClick={() => modalShow()} className="btn btn-add">{t('add')}</div>
                <h1>{t('permissions')}</h1>

                <div className="alert alert-success alert-add" role="alert">
                    {t('newrole')}
                </div>

                <div className="alert alert-success alert-delete" role="alert">
                    {t('delrole')}
                </div>

                <div className="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>


                <div className="DataTable_Container">
                    <table id="DataTable" className="table responsive nowrap">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('name')}</th>
                                <th>{t('delete')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles.map((role, i) => {
                                return (
                                    <tr>
                                        <td onClick={() => navigate(`/manage/permissions/role/${role.id}`)}>{t(`${role.slug ? role.slug : role.name}`)}</td>
                                        <td><i onClick={() => confirmDelete(t('delitem'), function () { delRole(role.id, role.name) })} style={{ float: 'right', color: '#ffffff', marginTop: '8px', marginRight: '10px', fontSize: '13px', cursor: 'pointer' }} className="btn-delete fas fa-trash-alt"></i></td>
                                    </tr>);
                            })}
                        </tbody>
                    </table>
                </div>

                <ModalDelete />

                {showModal && (<div style={{ display: 'block' }} id="add" className="modal fade in">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h4 className="modal-title">{t('addrole')}</h4>
                            <div onClick={() => modalHide()} className="close">&times;</div>
                        </div>

                        <div className="modal-body">
                            <form onSubmit={(event) => addRole(newid, event)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="rolename">{t('rolename')}</label>
                                            <input type="text" name="rolename" id="rolename" className="form-control" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6" onClick={() => modalHide()}>
                                        <button id="cancel" className="btn btn-red cancel">{t('cancel')}</button>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="submit" className="btn btn-green">{t('add')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> )}
            </div>
        </React.Fragment>
    );
}

export default withAuth(Permissions);
import React, { useState, useEffect } from 'react';
import './Phonebook.css';
import withRouter from '../../components/withRouter';
import { initDataTable, filterDataTable, fillDataTableFilters } from "../../Functions.js";
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const PhonebookInternal = () => {
    const [contacts, setContacts] = useState([]);
    const { t } = useTranslation();

    contacts.map((contact, i) => {
        contact.teams.map((team, i) => {
            fillDataTableFilters(document.getElementById('EmployeesTeams'), team.name);
        });
    })

    const getData = async() => {
        const book = await import("../../data/phonebook");
        const data = await book.getIntPhonebook();

        await Promise.all(
            data.map(async (user) => {
                const [device, teams] = await Promise.all([
                    book.getDeviceofUser(user.deviceid),
                    book.getTeamsOfContact(user.id)
                ]);

                user.devicedata = device;
                user.teams = teams;
            })
        );

        setContacts(data);
        initDataTable();
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <React.Fragment>
            <LocationAlert />
            
            <div className="loc_content">
                <h1>{t('phonebook')} - {t('phonebookintern')}</h1>
                <div className="filters">
                    {t('team')}:
                    <select id="EmployeesTeams" onChange={(event) => filterDataTable("reporthistoryalarm-employeesteams", 1, event.target.value, false)}>
                        <option data-id="0" value="">{t('select')}</option>
                    </select>

                    <div id="buttons" style={{ float:'right' }}></div>
                </div>

                

                <div className="DataTable_Container">
                    <table id="DataTable" className="showHead table responsive nowrap">
                        <thead>
                            <tr>
                                <th>{t('user')}</th>
                                <th>{t('teams')}</th>
                                <th>{t('device')}</th>
                                <th>{t('devicenumber')}</th>
                            </tr>
                        </thead>
                    <tbody>
                        {contacts.map((contact, i) => {
                          return (
                            <tr key={i}>
                                <td><div className="contact-icon">{contact.user.charAt(0)}</div> {contact.user}</td>
                                <td>
                                    {contact.teams.map((team, i) => {
                                      return <div className='contact-team'>{team.name}</div>;
                                    })}
                                </td>
                                <td>{contact.device}</td>
                                <td>
                                    {contact.devicedata.map((device, i) => {
                                      return device.devicetypeparameterid === 3 ? device.devicetypeparameterid === 3 : null
                                    })}
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );  
}

export default withRouter(PhonebookInternal);
import React, { Component } from 'react';
import './Settings.css';
import withAuth from '../components/Login/withAuth';
import { deleteVideo, getVideos, insertVideo, sortVideo, uploadVideo } from "../data/video.js";
import { initDataTable, confirmDelete } from "../Functions.js";
const $ = require('jquery');

class SettingsVideo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      selectedFile: null,
    }
  }

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  onFileUpload = (e) => {

    e.preventDefault();

    const data = new FormData();
    data.append("file", this.state.selectedFile);

    uploadVideo(this.state.selectedFile.name).then(function (response) {
      window.location.reload();
    })
  };

  DeleteVideo = (row, videofile) => {

    var Table = $('#DataTable').DataTable();

    deleteVideo(videofile).then(data => {
      Table.row(row.target.parentNode.parentNode).remove().draw();
    })
  }

  componentDidMount() {

    getVideos().then(data => {

      this.setState({
        videos: data
      });

      initDataTable();
    })
  }

  uploadVideo(e) {

    insertVideo(e.target.files[0].name, "helpvideo").then(data => {
      window.location.reload();
    })

  }

  render() {

    var $this = this;

    $(function () {
      $(".sortTable tbody").sortable({
        cursor: "move",
        placeholder: "sortable-placeholder",
        helper: function (e, tr) {
          var $originals = tr.children();
          var $helper = tr.clone();
          $helper.children().each(function (index) {
            $(this).width($originals.eq(index).width());
          });
          return $helper;
        },
        stop: function (event, ui) {
          var videoid = ui.item[0].dataset.videoid;
          var sort = ui.item.index();

          sortVideo(videoid, sort);
        }
      }).disableSelection();
    });

    return (
      <React.Fragment>
        <h2>Instructievideo</h2>
        <div class="row">
          <div class="col-md-12">

            <form action="" method="POST" encType="multipart/form-data" onSubmit={this.onFileUpload}>
              <div class="uploadfield">
                <input onChange={this.onFileChange} type="file" name="target_file" required />
                <input type="submit" value="Uploaden" />
              </div>
            </form>

            <h2>Alle video's</h2>
            <div className="DataTable_Container">
              <table id="DataTable" class="table responsive nowrap sortTable" width="100%">
                <thead>
                  <tr class="rowtitle">
                    <th>Naam</th>
                    <th>Verwijderen</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.videos.map((video, i) => {
                    return (
                      <tr data-videoid={video.id}>
                        <td onClick={() => { window.open(window.location.origin + '/video/' + video.file, '_blank'); }}><i class="fas fa-video"></i> {video.file}</td>
                        <td style={{ textAlign: 'right' }}><i style={{ marginRight: '10px' }} onClick={(event) => confirmDelete(function () { $this.DeleteVideo(event, video.file) })} class="btn-delete fas fa-trash-alt"></i></td>
                      </tr>)
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="modal" id='modal_dialog'>
          <div className="modal-content" style={{ textAlign: 'center' }}>
            <div className="modal-header">
              <h4 className="modal-title">Verwijderen</h4>
              <div className="close">&times;</div>
            </div>
            <div className="modal-body">
              <i class="fas fa-exclamation-circle"></i>
              <div class='modalTitle'></div>

              <div className="row">
                <div className="col-md-6" style={{ padding: '0' }}>
                  <button id="cancel btnNo" className="btn btn-red cancel">Annuleren</button>
                </div>
                <div className="col-md-6" style={{ padding: '0' }}>
                  <button id='btnYes' className="btn btn-green">Verwijderen</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withAuth(SettingsVideo);

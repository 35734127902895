import React, { Component } from 'react';
import withRouter from '../components/withRouter';
import { useTranslation } from 'react-i18next';

const IconPicker = (props) => {
    const { t } = useTranslation();
    
    return (
        <React.Fragment>
            <input type="text" style={{ display: 'none' }} name="moduleicon" id="moduleicon" defaultValue={props.currenticon} className="form-control" />
            <div className='iconpicker'>
                <div class="icon-preview" style={{ display: 'inline-block', marginRight: '15px' }} data-toggle="tooltip" title="" data-original-title="Preview of selected Icon">
                    <i style={{ fontSize: '20px', color: '#00529c' }} id="IconPreview" class={props.currenticon ? props.currenticon : 'fa-solid fa-question'}></i>
                </div>
                <button type="button" style={{ display: 'inline-block' }} id="GetIconPicker" data-iconpicker-input="input#moduleicon" data-iconpicker-preview="i#IconPreview">{t('selecticon')}</button>
            </div>
        </React.Fragment>
    );
}

export default withRouter(IconPicker);

import React, { Component, useEffect, useState } from 'react';
import withAuth from '../components/Login/withAuth';
import { confirmDelete, crudAlertControl, initDataTable } from "../Functions.js";
import ModalDelete from "../components/ModalDelete";
import { useNavigate } from 'react-router';
import LocationAlert from '../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const ModulesCategories = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const addCategory = async (event) => {
        event.preventDefault();

        const modules = await import("../data/modules.js");
        const addData = await modules.addCategories(event.target.categoryname.value);

        crudAlertControl('add', addData);
        await getCategories(false);
        modalHide();
    }

    const deleteCategorie = async (row, moduleid, modulename) => {
        const modules = await import("../data/modules.js");
        const deleteData = await modules.deleteCategories(moduleid, modulename);

        crudAlertControl('delete', deleteData);
        await getCategories(false);
    }

    const getCategories = async () => {
        const modules = await import("../data/modules.js");
        const categories = await modules.getCategories();

        setCategories(categories);
        initDataTable(); 
    }

    const modalShow = () => {
        setShowModal(true);
    }

    const modalHide = () => {
        setShowModal(false);
    }

    useEffect(() => {
        getCategories(true);
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <div onClick={() => { navigate("/manage/modules") }} className="btn btn-blue">{t('modules')}</div>
                <div onClick={() => modalShow()} className="btn btn-add">{t('add')}</div>
                <h1>{t('modules')} - {t('categories')}</h1>

                <div className="alert alert-success alert-add" role="alert">
                    {t('newcategory')}
                </div>

                <div className="alert alert-success alert-delete" role="alert">
                    {t('delcategory')}
                </div>

                <div class="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>

                <div className="DataTable_Container">
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('name')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map((categorie, i) => {
                                return (
                                    <tr style={{ cursor: 'pointer' }} key={i}>
                                        <td>{t(`${categorie.slug ? categorie.slug : categorie.name}`)}</td>
                                        <td>
                                            <i onClick={(event) => confirmDelete(function (event) { deleteCategorie(event, categorie.id, categorie.name) })} style={{ float: 'right', color: '#ffffff', marginTop: '3px', marginRight: '20px', cursor: 'pointer' }} class="btn-delete fas fa-trash-alt"></i>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <ModalDelete />

                {showModal && ( <div style={{ display: 'block' }} id="add" class="modal fade in">
                    <div class="modal-content ">
                        <div class="modal-header">
                            <h4 class="modal-title">{t('addcategory')}</h4>
                            <div onClick={() => modalHide()} class="close">&times;</div>
                        </div>

                        <div class="modal-body">
                            <form onSubmit={(event) => addCategory(event)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div class="form-group">
                                            <label for="categoryname">{t('categoryname')}</label>
                                            <input type="text" name="categoryname" id="categoryname" class="form-control" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div onClick={() => modalHide()} id="cancel" className="btn btn-red cancel">{t('cancel')}</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="submit" className="btn btn-green">{t('add')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> )}
            </div>
        </React.Fragment>
    );
}

export default withAuth(ModulesCategories);
import React, { Component, useEffect, useState } from 'react';
import './Manage.css';
import '../components/DataTables.css';
import withAuth from '../components/Login/withAuth';
import { switchVisibility, switchDefaultOn } from "../data/teams.js";
import { initDataTable, confirmDelete, crudAlertControl } from "../Functions.js";
import ModalDelete from "../components/ModalDelete";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const Teams = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [teams, setTeams] = useState([]);
    const [locations, setLocations] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const bulk = [];

    const getAllTeams = async () => {
        const [teamsData, objectsData] = await Promise.all([
            import("../data/teams.js"),
            import("../data/objects.js")
        ])
        const data = await teamsData.getTeams();
        const objects = await objectsData.getObjects();
        setTeams(data);
        setLocations(objects);
        initDataTable();
    }

    const insertTeam = async (event) => {
        event.preventDefault();

        const teamsData = await import("../data/teams.js");
        const data = await teamsData.addTeam(event.target.teamname.value, event.target.pbxgrpnum.value, event.target.teamrelated.value);
        
        if(data && data.length > 0){
            navigate('/manage/team/' + data[0].teamid);
        }
    }

    const deleteTeam = async (row, objectID, teamname) => {
        const teamsData = await import("../data/teams.js");
        const data = await teamsData.delTeam(objectID, teamname);
        getAllTeams();
        crudAlertControl('delete', data);
    }

    const bulkDeleteTeam = async (event, array) => {
        const teamsData = await import("../data/teams.js");
        array.forEach(async (team) => {
            const data = await teamsData.delTeam(team);
        })
    }

    const modalShow = () => {
        setShowModal(true);
    }

    const modalHide = () => {
        setShowModal(false);
    }

    useEffect(() => {
        getAllTeams();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <div onClick={() => modalShow()} className="btn btn-add">{t('add')}</div>
                <h1>{t('teams')}</h1>
                

                <div className="alert alert-success alert-add" role="alert">
                    {t('newteam')}
                </div>

                <div className="alert alert-success alert-delete" role="alert">
                    {t('delteam')}
                </div>

                <div class="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>

                <div className="DataTable_Container">
                    <div class="bulk" style={{ marginTop: '20px' }}>
                        <span style={{ color: '#000000', marginRight: '10px' }}>{t('bulkselect')}: </span>
                        <i onClick={(event) => confirmDelete(function () { bulkDeleteTeam(event, bulk) })} className="btn-delete fas fa-trash-alt"></i>
                    </div>
                
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr class="rowtitle">
                                <th>{t('name')}</th>
                                <th>{t('location')}</th>
                                <th>{t('pbxqueuenumber')}</th>
                                <th>{t('alwayson')}</th>
                                <th>{t('visible')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {teams.map((team, i) => {
                                var selected = team.invisible === 0 ? true : false;
                                var selected2 = team.defaulton === 1 ? true : false;

                                return (
                                    <tr key={i}>
                                        <td onClick={(event) => { navigate(`/manage/team/${team.teamid}`) }}>{team.name}</td>
                                        <td>{team.locationname}</td>
                                        <td>{team.pbxqueuenum}</td>
                                        <td>
                                            <label class='switch'>
                                            <input id={`team_${team.id}`} onClick={(event) => { switchDefaultOn(event.target.checked === true ? 1 : 0, team.teamid, team.name) }} defaultChecked={selected2} class='teamtogBtn' type='checkbox' />
                                            <span class='slider round'></span></label>
                                        </td>
                                        <td>
                                            <label class='switch'>
                                            <input id={`team_${team.id}`} onClick={(event) => { switchVisibility(event.target.checked === true ? 0 : 1, team.teamid, team.name) }} defaultChecked={selected} class='teamtogBtn' type='checkbox' />
                                            <span class='slider round'></span></label>
                                        </td>
                                        <td><i onClick={(event) => confirmDelete(t('delitem'), function () { deleteTeam(event, team.teamid, team.name) })} class="btn-delete fas fa-trash-alt"></i></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <ModalDelete />

            {showModal && ( <div style={{ display: 'block' }} id="add" class="modal fade in">
                <div class="modal-content ">
                    <div class="modal-header">
                        <h4 class="modal-title">{t('addteam')}</h4>
                        <div onClick={() => modalHide()} class="close">&times;</div>
                    </div>

                    <div class="modal-body">
                        <form onSubmit={(event) => insertTeam(event)}>
                            <div className="row">
                                <div className="col-md-12" style={{ padding: '0' }}>
                                    <div class="form-group">
                                        <label for="teamname">{t('name')}</label>
                                        <input type="text" name="teamname" id="teamname" class="form-control" required />
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ padding: '0' }}>
                                    <div class="form-group">
                                        <label for="teamrelated">{t('location')}</label>
                                            <select name="teamrelated" id="teamrelated" class="form-control" required>
                                                <option value="">{t('select')}</option>
                                                    {locations.map((location, i) => {
                                                        return (<option value={location.objectid}>{location.objectname}</option>)
                                                    })}
                                            </select>
                                    </div>
                                </div>
                                <div className="col-md-12" style={{ padding: '0' }}>
                                    <div class="form-group">
                                        <label for="pbxgrpnum">{t('pbxqueuenumber')}</label>
                                        <input type="text" name="pbxgrpnum" id="pbxgrpnum" class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6" style={{ padding: '0' }}>
                                    <div onClick={() => modalHide()} id="cancel" className="btn btn-red cancel">{t('cancel')}</div>
                                </div>
                                <div className="col-md-6" style={{ padding: '0' }}>
                                    <button type="submit" className="btn btn-green">{t('add')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> )}
        </React.Fragment>
    );
}

export default withAuth(Teams);

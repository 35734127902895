import React, { Component, useEffect, useState } from 'react';
import withAuth from '../components/Login/withAuth';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const Logging = () => {
    const [logging, setLogging] = useState([]);
    const { t } = useTranslation();

    const getData = async () => {
        const loggingData = await import("../data/logging");
        const functions = await import("../Functions");
        const data = await loggingData.getOverallLogging();

        setLogging(data);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <h1>Logging</h1>

                <div className="DataTable_Container">
                    <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('type')}</th>
                                <th>{t('object')}</th>
                                <th>{t('message')}</th>
                                <th>{t('date')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logging.map((log, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{log.type}</td>
                                        <td>{log.name}</td>
                                        <td>{log.message}</td>
                                        <td>{moment(log.datecreated).format("YYYY-MM-DD HH:mm")}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withAuth(Logging);
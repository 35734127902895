import React, { Component, useEffect, useState } from 'react';
import './Reports.css';
import '../../components/DataTables.css';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import LocationAlert from "../../components/LocationAlert.js";
import { useTranslation } from 'react-i18next';
import AlarmNotes from '../../components/AlarmNotes.js';
moment.locale('nl');

const ReportHistoryAlarmDetails = (props) => {
    const { t } = useTranslation();
    const [alarm, setAlarm] = useState([]);
    const [teams, setTeams] = useState([]);
    const [logs, setLogs] = useState([]);
    const [route, setRoute] = useState([]);
    const [users, setUsers] = useState([]);
    const [showRoute, setShowRoute] = useState(false);

    var nameAccepted = '';
    var teamAccepted = '';
    var nameFinished = '';
    var teamFinished = '';
    var nameCanceled = '';
    var teamCanceled = '';

    const getData = async () => {
        const [reports, routes] = await Promise.all([
          import("../../data/reports.js"),
          import("../../data/routes.js")
        ])

        const data = await reports.getHistoryAlarms(props.router.params.id);
        setAlarm(data[0]);
        const step = await routes.getStep(data[0].route);
        setRoute(step);
        const teams = await reports.getTeamsOfAlarms(props.router.params.id);
        await Promise.all(teams.map(async (team) => {
            const logs = await reports.getLogOfTeam(team.objectalarmteamid, props.router.params.id);
            team['log'] = logs;
            const users = await reports.getUsersOfAlarm(props.router.params.id, data[0].uptriggered, team.teamid);

            const usersArray = [...new Set(users.map(user => user.user))];
            team.users = usersArray;
            setTeams(teams);
        }))
    }

    const showRoutePopup = () => {
        setShowRoute(true);
    }

    const hideRoutePopup = () => {
        setShowRoute(false);
    }

    useEffect(() => {
        getData();
    }, [])

    console.log(alarm);

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <div class="row">
                    <div class="col-md-12">
                        <h1><button className="goBackBtn" onClick={() => window.history.back()}><i class="fas fa-arrow-circle-left"></i></button> {alarm.name} ({t(`${alarm.slug}`)}) - {alarm.uptriggered ? moment(alarm.uptriggered).format('DD-MM-YYYY HH:mm:ss') : ''}</h1>
                    </div>

                    <div className='col-md-12'>
                        <div className="status">
                            <h2>{t('status')} <i onClick={() => showRoutePopup()} class="route-info fas fa-info-circle"></i></h2>
                            {showRoute && (<div className='alarm-route'>
                                <i onClick={() => hideRoutePopup()} class="closeroute fas fa-times-circle"></i>
                                <h2>{t('route')}</h2>
                                {route.map((route, i) => {
                                    return (
                                        <div className='routeteam'>
                                            <div className='team'>
                                                <i class="fas fa-users"></i>
                                                {route.teamname}
                                                <span className='teamsequence'>
                                                    {route.sequencetimeout} {t('seconds')}
                                                </span>
                                            </div>
                                            <div className='sequence-arrow'>
                                                <i class="fas fa-arrow-right"></i>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>)}
                            <div className="status-info">
                                <div className="status-teams">
                                    {teams.map((team, i) => {
                                        var teamReceived = 0;

                                        if (team && team.log) {
                                            team.log.forEach(log => {
                                                switch (log.responsecontent) {
                                                    case "receive":
                                                        teamReceived++;
                                                        break;
                                                    case "accept":
                                                        nameAccepted = log.name;
                                                        teamAccepted = team.name;
                                                        break;
                                                    case "confirm":
                                                        nameFinished = log.name;
                                                        teamFinished = team.name;
                                                        break;
                                                    case "declined":
                                                        nameCanceled = log.name;
                                                        teamCanceled = team.name;
                                                        break;
                                                }
                                            });
                                        }

                                        return (
                                            <div className='status-team'>
                                                <div className='team'>
                                                    <i className={`${teamReceived > 0 ? 'teamReceived' : 'teamNotReceived'} fas fa-users`}></i>
                                                    <span class={`badge-pill ${teamReceived > 0 ? 'teamReceived' : 'teamNotReceived'}`}>{team.name}</span>
                                                </div>

                                                <div className='sequence-arrow'>
                                                    <i class="fas fa-arrow-right"></i>
                                                </div>
                                            </div>)
                                    })}
                                </div>
                                <div className="border-divider"></div>
                                <div className='status-accepted'>
                                    <i className={`fas ${!alarm.canceltriggered && alarm.downtriggered ? 'fa-check-circle' : 'fa-times-circle'}`}></i>
                                    <span>{alarm.canceltriggered ? t('canceled') : t('accepted')} <div className={`${alarm.canceltriggered || alarm.downtriggered ? 'showAcceptedName' : 'hideAcceptedName'}`}>{alarm.canceltriggered ? nameCanceled : nameAccepted} - {alarm.canceltriggered ? teamCanceled : teamAccepted} <br />{t('on')} {alarm.canceltriggered ? moment(alarm.canceltriggered).format('DD-MM-YYYY HH:mm:ss') : (alarm.downtriggered ? moment(alarm.downtriggered).format('DD-MM-YYYY HH:mm:ss') : '')}</div></span>
                                </div>
                                <div className="border-divider"></div>
                                <div className='status-finished'>
                                    <i className="fas fa-flag-checkered" style={{ color: !alarm.canceltriggered && alarm.finishtriggered ? '#009f07' : '#c7cad9' }}></i>
                                    <span>{t('solved')} <div className={!alarm.canceltriggered && alarm.finishtriggered ? 'showAcceptedName' : 'hideAcceptedName'}>{!alarm.canceltriggered && alarm.finishtriggered ? nameFinished + ' - ' + teamFinished : ''} <br /> {t('on')} {moment(alarm.finishtriggered).format('DD-MM-YYYY HH:mm:ss')}</div></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <h2>{t('loggedinemployees')}</h2>
                        <div className="alarm-employees">
                            <table width="100%" style={{ tableLayout: 'fixed' }}>
                                {teams.map((team, i) => {
                                    return (
                                        <tr>
                                            <td valign="top" style={{ paddingTop: '14px' }}>{team.name}</td>
                                            <td valign="top">
                                                <table width="100%" style={{ tableLayout: 'fixed' }}>
                                                    {team && team.users ? team.users.map((user, i) => {
                                                        return (
                                                            <tr>
                                                                <td>{user}</td>
                                                            </tr>
                                                        )
                                                    }) : ''}
                                                </table>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </table>
                        </div>

                        <h2>{t('alarmlogs')}</h2>
                        <div className="alarm-logs">
                            <table width="100%" style={{ tableLayout: 'fixed' }}>
                                {teams.map((team, i) => {
                                    return (
                                        <tr>
                                            <td valign="top" style={{ paddingTop: '14px', width: '20%' }}>{team.name}</td>
                                            <td valign="top">
                                                <table width="100%" style={{ tableLayout: 'fixed' }}>
                                                    {team && team.log ? team.log.map((log, i) => {

                                                        var response = '';

                                                        if (log.responsecontent === "accept") {
                                                        response = t('accepted');
                                                        } else if (log.responsecontent === "confirm") {
                                                        response = t('confirmed');
                                                        } else if (log.responsecontent === "receive") {
                                                        response = t('received');
                                                        } else if (log.responsecontent === "ignored") {
                                                        response = t('ignored');
                                                        } else if (log.responsecontent === "declined") {
                                                        response = t('declined');
                                                        } else if (log.responsecontent === "opened") {
                                                        response = t('opened');
                                                        } else if (log.responsecontent === "camera opened") {
                                                        response = t('cameraopened');
                                                        }

                                                        return (
                                                          <tr>
                                                            <td>{log.name}</td>
                                                            <td>{response}</td>
                                                            <td>{moment(log.responsedon).format('DD-MM-YYYY HH:mm:ss')}</td>
                                                          </tr>
                                                        )
                                                    }) : ''}
                                                </table>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </table>
                        </div>
                    </div>

                    <div class="col-md-4">
                        {console.log(alarm)}
                        {alarm && alarm.objectalarmid ? <AlarmNotes alarm={alarm} /> : ''}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(ReportHistoryAlarmDetails);

import Config from "../config.json";

export const getDeviceTypes = (deviceid) => {
  var url = '';

  if (deviceid) {
    url = Config.API_URL + `/mba/devicetype/get/${deviceid}`;
  } else {
    url = Config.API_URL + "/mba/devicetype/get";
  }

  return fetch(url, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getDevices = (deviceid) => {
  var url = '';

  if (deviceid) {
    url = Config.API_URL + `/mba/device/get/${deviceid}`;
  } else {
    url = Config.API_URL + "/mba/device/get";
  }

  return fetch(url, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/json",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getLatestDevice = () => {
  return fetch(Config.API_URL + `/mba/device/latest`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/json",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data[0];
    })
}

export const getDeviceConfig = (deviceid) => {
  return fetch(Config.API_URL + `/mba/device/config/get/${deviceid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const insertDeviceParam = (deviceid, devicename, paramtype, paramvalue) => {
  return fetch(Config.API_URL + `/mba/device/param/insert/${deviceid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/json",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      'deviceid': deviceid,
      'name': devicename,
      'parameterid': paramtype,
      'value': paramvalue,
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const deviceChange = (device, deviceid, devicetype, objectid) => {
  return fetch(Config.API_URL + `/mba/objectdevicechange/${device}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/json",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      'deviceid': deviceid,
      'devicetype': devicetype,
      'objectid': objectid,
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const changeLocation = (deviceid, locationid) => {
  return fetch(Config.API_URL + `/mba/device/update/${deviceid}/location`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "deviceid": deviceid,
      "locationid": locationid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const insertDevice = (name, devicetypeid, locationid) => {
  return fetch(Config.API_URL + `/mba/device/insert/`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "name": name,
      "devicetypeid": devicetypeid,
      "locationid": locationid,
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const insertCustomDevice = (name, devicetypeid, locationid) => {
  return fetch(Config.API_URL + `/mba/device/custom/insert/`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "name": name,
      "devicetypeid": devicetypeid,
      "locationid": locationid,
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const deleteDevice = (deviceid, devicename) => {
  return fetch(Config.API_URL + `/mba/device/delete/${deviceid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "deviceid": deviceid,
      "name": devicename
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const updateDeviceField = (deviceid, name, field, value) => {
  return fetch(Config.API_URL + `/mba/device/${deviceid}/update/field`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "deviceid": deviceid,
      "name": name,
      "field": field,
      "value": value
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const updateDeviceConfField = (deviceid, name, paramid, value) => {
  return fetch(Config.API_URL + `/mba/device/${deviceid}/conf/update/field`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "deviceid": deviceid,
      "name": name,
      "paramid": paramid,
      "value": value
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getTriggerStatus = () => {
  return fetch(Config.API_URL + `/mba/device/status`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getNeckTrans = () => {
  return fetch(Config.API_URL + "/mba/device/necktrans/get", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation')
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const updateNeckTrans = (deviceid, devicename, value) => {
  return fetch(Config.API_URL + `/mba/device/necktrans/update`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/json",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      'deviceid': deviceid,
      'devicename': devicename,
      'value': value,
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}
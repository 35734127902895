import React, { Component } from 'react';
import withRouter from '../components/withRouter';
import { filterDataTable } from "../Functions";
import { registerLocale } from  "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import { useTranslation } from 'react-i18next';
registerLocale('nl', nl);

const Filter = (props) => {
    const {t} = useTranslation();
    return (
        <React.Fragment>
            {props.text}:
            {props.type === "select" ? 
            <select id={props.id} onChange={(event) => { filterDataTable(props.item, props.column, event.target.value, props.exact) }}>
                <option data-id="0" value="">{t('select')}</option>
            </select>
            : (props.type === "date" ? <DatePicker locale="nl" dateFormat="yyyy-MM-dd" selected={props.router.params.reportdate ? new Date(props.router.params.reportdate) : new Date()} onChange={props.callback}/> : "")}
        </React.Fragment>
    );
}

export default withRouter(Filter);

import React, { Component, useEffect, useState } from 'react';
import './Reports.css';
import '../../components/DataTables.css';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import LocationAlert from "../../components/LocationAlert.js";
import { useTranslation } from 'react-i18next';
import Export from '../../components/Export.js';
moment.locale('nl');

const NeckStatus = () => {
    const [statusses, setStatusses] = useState([]);
    const { t } = useTranslation();

    const getData = async () => {
        const [reports, functions] = await Promise.all([
            import("../../data/reports.js"),
            import("../../Functions.js")
        ])

        const data = await reports.getNeckStatus();
        setStatusses(data);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <div className="filters">
                    <Export />
                </div>

                <h2>{t('statustransmitters')}</h2>
                <div className="DataTable_Container">
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('device')}</th>
                                <th>{t('location')}</th>
                                <th>{t('lastsignal')}</th>
                                <th>{t('batlevel')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {statusses.map((status, i) => {
                                var percentage = status.percentage < 0 ? 0 : status.percentage;
                                const batStatus = percentage < 10 ? "batRed" : (percentage < 30 ? "batOrange" : "batGreen");

                                return (
                                    <tr>
                                        <td>{status.devicename}</td>
                                        <td>{status.objectname}</td>
                                        <td>{moment(status.lastheartbeat).format('DD-MM-YYYY HH:mm:ss')}</td>
                                        <td><span>{Math.floor(percentage)} %</span> <progress class={`neckbat ${batStatus}`} value={Math.floor(percentage)} max="100"></progress></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(NeckStatus);
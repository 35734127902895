import Config from "../config.json";

export const getTopAlarms24Hours = () => {
    return fetch(Config.API_URL + `/mba/stats/topalarms24hours`, {
        "method": "GET",
        "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
      })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getTopAlarms7days = () => {
    return fetch(Config.API_URL + `/mba/stats/topalarms7days`, {
        "method": "GET",
        "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
      })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getTopAlarmsMonth = () => {
    return fetch(Config.API_URL + `/mba/stats/topalarmsmonth`, {
        "method": "GET",
        "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
      })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getTopAlarmsYear = () => {
    return fetch(Config.API_URL + `/mba/stats/topalarmsyear`, {
        "method": "GET",
        "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
      })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getStatsDay = () => {
  return fetch(Config.API_URL + `/mba/stats/24hours`, {
      "method": "GET",
      "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
      }
  })
      .then(response => response.json())
      .then(alarms => {
          return alarms;
      })
}

export const getStatsWeek = () => {
  return fetch(Config.API_URL + `/mba/stats/7days`, {
      "method": "GET",
      "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
      }
  })
      .then(response => response.json())
      .then(alarms => {
          return alarms;
      })
}

export const getStatsMonth = () => {
  return fetch(Config.API_URL + `/mba/stats/month`, {
      "method": "GET",
      "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
      }
  })
      .then(response => response.json())
      .then(alarms => {
          return alarms;
      })
}

export const getStatsYear = () => {
    return fetch(Config.API_URL + `/mba/stats/year`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
    })
        .then(response => response.json())
        .then(alarms => {
            return alarms;
        })
}

export const getStatsTeams = () => {
    return fetch(Config.API_URL + `/mba/stats/teams`, {
        "method": "GET",
        "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
      })
        .then(response => response.json())
        .then(teams => {
            return teams;
        })
}

export const getStatsRooms24Hours = () => {
    return fetch(Config.API_URL + `/mba/stats/rooms24hours`, {
        "method": "GET",
        "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
      })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getStatsRooms7Days = () => {
    return fetch(Config.API_URL + `/mba/stats/rooms7days`, {
        "method": "GET",
        "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
      })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getStatsRoomsMonth = () => {
    return fetch(Config.API_URL + `/mba/stats/roomsmonth`, {
        "method": "GET",
        "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
      })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getStatsRoomsYear = () => {
    return fetch(Config.API_URL + `/mba/stats/roomsyear`, {
        "method": "GET",
        "headers": {
          "Authorization": "Bearer " + localStorage.getItem('id_token'),
          "Content-Type": "application/x-www-form-urlencoded",
          "userid": localStorage.getItem('UserID'),
          "customerid": localStorage.getItem('CustomerID'),
          "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
      })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}
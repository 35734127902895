import React, { useEffect, useState } from 'react';
import withAuth from '../components/Login/withAuth';
import Menu from './components/Menu';
import { useNavigate } from 'react-router';
import { checkMBAUserPermission } from './Functions';
import { confirmDelete, initDataTable } from '../Functions';
import ModalDelete from '../components/ModalDelete.js';
import { useTranslation } from 'react-i18next';
import Config from '../config.json';

const Templates = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [templates, setTemplates] = useState([]);
    const [types, setTypes] = useState([]);
    const [connections, setConnections] = useState([]);
    const [messages, setMessages] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [incmsg, setIncMsg] = useState([]);
    const [timetables, setTimetables] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [tempwwpass, setTempwwpass] = useState('');

    const getData = async() => {
        const [responserules, clusters, gettimetables, templates, devices, organisation] = await Promise.all([
            import("./data/ResponseRules"),
            import("./data/Clusters"),
            import("./data/Timetables"),
            import('./data/Templates'),
            import("../data/devices.js"),
            import("../data/organisation"),
        ])

        const types = await devices.getDeviceTypes();
        setTypes(types);

        const conndata = await responserules.getConnections();
        setConnections(conndata);

        const msg = await organisation.getOutGoingMessages();
        setMessages(msg);

        const incmsgs = await organisation.getIncomingMessages();
        setIncMsg(incmsgs);

        const timetablesdata = await gettimetables.getTimetables();
        setTimetables(timetablesdata);

        const clusterdata = await clusters.getRuleClusters();
        setClusters(clusterdata);

        const gettemplates = await templates.getAllTemplates();
        setTemplates(gettemplates);

        initDataTable();
    }

    const addTemplate = async (event) => {
        event.preventDefault();

        const data = await import('./data/Templates');
        const template = await data.insertTemplate(
            event.target.devicetype.value, 
            event.target.incoming.value, 
            event.target.incomingmsg.value, 
            event.target.outgoing.value, 
            event.target.outgoingmsg.value,
            event.target.timetable.value,
            event.target.cluster.value,
            event.target.priority.value ? event.target.priority.value : "0"
        );

        await getData();
        setShowModal(false);
    }

    const delTemplate = async (templateid) => {
        const data = await import("./data/Templates");
        const template = await data.deleteTemplate(templateid);
        await getData();
    }

    const temppass = async (event) => {
        console.log(tempwwpass, Config['tempww']);
        if(tempwwpass === Config['tempww']){
            localStorage.setItem('temp_ww', tempwwpass);
            window.location.reload();
        }
    }

    useEffect(() => {
        checkMBAUserPermission();
        getData();

        if(localStorage.getItem('temp_ww') && localStorage.getItem('temp_ww') === Config['tempww']){
            document.getElementById('mba_templates').style.display = "block";
            document.getElementById('tempLogin').style.display = "none";
        }else{
            document.getElementById('mba_templates').style.display = "none";
            document.getElementById('tempLogin').style.display = "block";
        }
    }, [])

    return(
        <React.Fragment>
            <Menu />
            <div className="DataTable_Container" id="tempLogin" style={{ background: '#ffffff', padding: '30px', marginTop: '20px' }}>
            <p style={{ color: '#333' }}>Dit is een extra beveiligde gedeelte. Vul hier het benodigde wachtwoord in:</p>
            <input onChange={(event) => setTempwwpass(event.target.value)} type="password" className="form-control" />
            <button onClick={(event) => temppass(event)} className='btn btn-blue' style={{ float: 'none', margin: '20px 0 0 0' }}>Invoeren</button>
            </div>

            <div id='mba_templates'>
            <h2>Templates</h2>
            <div onClick={() => {setShowModal(true)}} className="btn btn-add">Toevoegen</div>

            <div className="DataTable_Container">
                <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                    <thead>
                        <tr className="rowtitle">
                            <th>DeviceType</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {templates.map((template, i) => {

                            return (
                            <tr key={i}>
                                <td onClick={() => navigate('/mba-admin/responserules/templates/' + template.id)}>{template.devicetype}</td>
                                <td>
                                    <i onClick={() => confirmDelete(t('delitem'), function () { delTemplate(template.id) })} style={{ color: '#ff0000', marginTop: '3px', cursor: 'pointer' }} class="fas fa-trash-alt"></i>
                                </td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>

            <ModalDelete />

            {showModal && ( 
                <div style={{ display: 'block' }} id="add" className="modal fade in">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h4 className="modal-title">Template toevoegen</h4>
                            <div onClick={() => setShowModal(false)} className="close">&times;</div>
                        </div>

                        <div className="modal-body">
                            <form onSubmit={(event) => addTemplate(event)}>
                                <div className="row">
                                    <table width="100%">
                                        <tbody>
                                            <tr>
                                                <td>DeviceType</td>
                                                <td>
                                                    <select name="devicetype" id="devicetype" className='form-control' key="template_devicetype">
                                                        <option key="0" value="0">Selecteer devicetype</option>
                                                        {types.map((type, i) => {
                                                            return(<option key={i} value={type.devicetypeid}>{type.devicetypename}</option>)
                                                        })}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Incoming connection</td>
                                                <td>
                                                    <select name="incoming" id="incoming" className='form-control' key="template_incCon">
                                                        <option key="0" value="0">Selecteer connection</option>
                                                        {connections.map((connection, i) => {
                                                            return(<option key={i} value={connection.id}>{connection.name}</option>)
                                                        })}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Incoming messages</td>
                                                <td>
                                                    <select name="incomingmsg" id="incomingmsg" className='form-control' key="template_incmsg">
                                                        <option value="0">Selecteer message</option>
                                                        {incmsg.map((msg, i) => {
                                                            return <option key={i} value={msg.id}>{msg.name}</option>
                                                        })}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Outgoing connection</td>
                                                <td>
                                                    <select name="outgoing" id="outgoing" className='form-control' key="template_outCon">
                                                        <option key="0" value="0">Selecteer connection</option>
                                                        {connections.map((connection, i) => {
                                                            return(<option key={i} value={connection.id}>{connection.name}</option>)
                                                        })}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Outgoing messages</td>
                                                <td>
                                                    <select name="outgoingmsg" id="outgoingmsg" className='form-control' key="template_outMsg">
                                                        <option value="0">Selecteer message</option>
                                                        {messages.map((msg, i) => {
                                                            return <option key={i} value={msg.id}>{msg.name}</option>
                                                        })}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Timetable</td>
                                                <td>
                                                    <select name="timetable" id="timetable" className='form-control' key="template_timetable">
                                                        <option value="0">Selecteer timetable</option>
                                                        {timetables.map((timetable, i) => {
                                                            return <option key={i} value={timetable.id}>{timetable.name}</option>
                                                        })}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cluster</td>
                                                <td>
                                                    <select name="cluster" id="cluster" className='form-control' key="template_cluster">
                                                        <option value="0">Selecteer cluster</option>
                                                        {clusters.map((cluster, i) => {
                                                            return <option key={i} value={cluster.id}>{cluster.name}</option>
                                                        })}
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Priority</td>
                                                <td>
                                                    <input type="text" id="priority" name="priority" className='form-control' />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div onClick={() => setShowModal(false)} id="cancel" className="btn btn-red cancel">Annuleren</div>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="submit" className="btn btn-green">Toevoegen</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> )}

                </div>
        </React.Fragment>
    )
}

export default withAuth(Templates);
import Config from "../../config.json";

export const getAllMessages = (id) => {
    return fetch(Config.API_URL + "/mba/admin/messages/get", {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "connectionid": id ? id : ''
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getMessage = (id) => {
    return fetch(Config.API_URL + "/mba/admin/messages/" + id, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "msgid": id ? id : ''
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getMessageTypes = () => {
    return fetch(Config.API_URL + "/mba/admin/messages/types/get", {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getMessageDescriptions = () => {
    return fetch(Config.API_URL + "/mba/admin/messages/descriptions/get", {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const insertMsg = (name, content, connection, msgtype, msgdesc, incoming, outgoing, report) => {
    return fetch(Config.API_URL + "/mba/admin/message/insert", {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "name": name,
            "content": content,
            "connectionid": connection,
            "msgtypeid": msgtype,
            "msgdescid": msgdesc,
            "incoming": incoming,
            "outgoing": outgoing,
            "report": report
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const copyMsg = (id) => {
    return fetch(Config.API_URL + "/mba/admin/messages/" + id + '/copy', {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "msgid": id
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const delMsg = (id) => {
    return fetch(Config.API_URL + "/mba/admin/messages/" + id + '/delete', {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "msgid": id
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const updateMsg = (id, name, content, connection, msgtype, msgdesc, incoming, outgoing, report) => {
    return fetch(Config.API_URL + "/mba/admin/messages/" + id + '/update', {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "msgid": id,
            "name": name,
            "content": content,
            "connectionid": connection,
            "msgtypeid": msgtype,
            "msgdescid": msgdesc,
            "incoming": incoming,
            "outgoing": outgoing,
            "report": report
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}
import Config from "../config.json";

export const getCams = () => {
  return fetch(Config.API_URL + "/mba/virtualtour/cams", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID')
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getRooms = () => {
  return fetch(Config.API_URL + "/mba/virtualtour/rooms", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type" : "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid" : localStorage.getItem('CustomerID'),
    }
  })
  .then(response => response.json())
  .then(rooms => {
    return rooms;
  })
}

export const InsertRoom = (roomid) => {
  return fetch(Config.API_URL + `/mba/virtualtour/insert`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "roomid": roomid,
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const deleteRoom = (roomid) => {
  return fetch(Config.API_URL + `/mba/virtualtour/delete/${roomid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "tourid": roomid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const sortCam = (tourid, sort) => {
  return fetch(Config.API_URL + `/mba/virtualtour/cam/${tourid}/sort`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "tourid": tourid,
      "sort": sort
    }
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
}
import React, { Component, useState, useEffect } from 'react';
import '../../modules/Dashboard/Dashboard.css';
import { Link } from "react-router-dom";
import withRouter from '../../components/withRouter';
import LocationAlert from '../../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const DashboardStandard = (props) => {
    const { t } = useTranslation();
    const [tabcards, setTabcards] = useState([]);
    const [tabindex, setTabindex] = useState(0);
    const [currenttab, setCurrenttab] = useState(0);
    const [showMenu, setShowMenu] = useState(false);

    const fetchData = async (filter) => {
      const [stats, chart] = await Promise.all([
        import("../../data/stats.js"),
        import("../Chart.js")
      ]);

      let alarms;

      switch (filter) {
        case 'year':
          alarms = await stats.getStatsYear();
          chart.getAlarmsYear(alarms);
          chart.getAlarmsTop10_Year(t);
          chart.getRoomsTop10_Year(t);
          break;
        case 'month':
          alarms = await stats.getStatsMonth();
          chart.getAlarmsMonth(alarms);
          chart.getAlarmsTop10_Month(t);
          chart.getRoomsTop10_Month(t);
          break;
        case 'week':
          alarms = await stats.getStatsWeek();
          chart.getAlarms7Days(alarms);
          chart.getAlarmsTop10_7Days(t);
          chart.getRoomsTop10_7Days(t);
          break;
        default:
          alarms = await stats.getStatsDay();
          chart.getAlarms24Hours(alarms);
          chart.getAlarmsTop10_24Hours(t);
          chart.getRoomsTop10_24Hours(t);
          break;
      }

      chart.getTeams();
    };

    const menuShow = () => {
        setShowMenu(true);
    }

    const menuHide = () => {
        setShowMenu(false);
    }

    const filterData = (period) => {
        fetchData(period);
        menuHide();
    }

    useEffect(() => {
        fetchData(props.router.location.hash.split("#")[1]);
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
            <i className="dash_view fas fa-ellipsis-v" onClick={() => menuShow()}></i>
            <h1>Dashboard</h1>
            {showMenu && (
                <div className="dash_view_options">
                    <span>{t('graphs')}</span>
                    <ul className="alarms_filters">
                        <Link to='/#day' style={{ color: '#000000', textDecoration: 'none' }}><li onClick={() => filterData('day')} className={`btn btn-alarms-filter btn-24hours ${props.router.location.hash.split("#")[1] === 'day' ? 'current' : ''}`}>{t('24hours')}</li></Link>
                        <Link to='/#week' style={{ color: '#000000', textDecoration: 'none' }}><li onClick={() => filterData('week')} className={`btn btn-alarms-filter btn-7days ${props.router.location.hash.split("#")[1] === 'week' ? 'current' : ''}`}>{t('7days')}</li></Link>
                    </ul>

                    <span>{t('settings')}</span>
                    <ul>
                        <li><Link to='/editor' style={{ color: '#000000', textDecoration: 'none' }}>{t('editdashboard')}</Link></li>
                    </ul>
                </div>
            )}

                <div className="container-fluid dashboard">
                    <div className="grid row">

                    

                        <div className="grid-item col-md-8">
                            <div className="grid-item-content">
                                <div className='card'>
                                    <h2>{t('historyalarms')}</h2>
                                    <div className="card-body">
                                        <canvas className="line-graph" id="alarms" width="400" height="200"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item col-md-4">
                            <div className="grid-item-content">
                                <div className='card'>
                                    <h2>{t('topalarms')}</h2>
                                    <div className="card-body">
                                        <canvas className="line-graph" id="alarmstop" width="400"></canvas>
                                        <div id="legend" className="legend-day"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item col-md-6">
                            <div className="grid-item-content">
                                <div className='card'>
                                    <h2>{t('top10rooms')}</h2>
                                    <div className="card-body">
                                        <canvas className="line-graph" id="rooms" width="400" height="200"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-item col-md-6">
                            <div className="grid-item-content">
                                <div className='card'>
                                    <h2>{t('employeesperteam')}</h2>
                                    <div className="card-body">
                                        <canvas id="team" width="400"></canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(DashboardStandard);
import React, { Component, useEffect, useState } from 'react';
import '../../modules/Dashboard/Dashboard.css';
import withAuth from '../Login/withAuth';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as moment from 'moment';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import withRouter from '../../components/withRouter';
import LocationAlert from '../../components/LocationAlert';
import TipsBlock from './TipsBlock';
import { useTranslation } from 'react-i18next';
import VirtualRoundCard from '../VirtualRoundCard.js';
const $ = require('jquery');
moment.locale('nl');

const DashboardCustom = (props) => {
    const {t} = useTranslation();
    const [tabscards, setTabscards] = useState([]);
    const [tabindex, setTabindex] = useState(0);
    const [currenttab, setCurrenttab] = useState(0);
    const [rooms, setRooms] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [showMenu, setShowMenu] = useState(false);

    const changeVolume = async (event) => {
        const dashboard = await import("../../data/dashboard.js");
        const data = dashboard.changeSoundLevel(event.target.dataset.object, event.target.value);
    }

    const roomAlarm = async (event) => {
        const alarms = await import("../../data/alarms.js");
        const data = event.target.dataset.value === 'Enable' ? alarms.enableAlarm(event.target.dataset.id, event.target.dataset.message) : alarms.disableAlarm(event.target.dataset.id, event.target.dataset.message);
        $(event.target).data('value', event.target.dataset.value === 'Enable' ? 'Disable' : 'Enabled');
        $(event.target).attr('class', event.target.dataset.value === 'Enable' ? 'alarm-icon alarmon' : 'alarm-icon alarmoff');
        return data;
    }

    const fetchData = async (filter) => {
        const [stats, chart] = await Promise.all([
            import("../../data/stats.js"),
            import("../Chart.js")
        ]);

        let alarms;

        switch (filter) {
            // case 'year':
            //     alarms = await stats.getStatsYear();
            //     chart.getAlarmsYear(alarms);
            //     chart.getAlarmsTop10_Year(alarms);
            //     chart.getRoomsTop10_Year(alarms);
            //     break;
            // case 'month':
            //     alarms = await stats.getStatsMonth();
            //     chart.getAlarmsMonth(alarms);
            //     chart.getAlarmsTop10_Month(alarms);
            //     chart.getRoomsTop10_Month(alarms);
            //     break;
            case 'week':
                alarms = await stats.getStatsWeek();
                chart.getAlarms7Days(alarms);
                chart.getAlarmsTop10_7Days(t);
                chart.getRoomsTop10_7Days(t);
                break;
            default:
                alarms = await stats.getStatsDay();
                chart.getAlarms24Hours(alarms);
                chart.getAlarmsTop10_24Hours(t);
                chart.getRoomsTop10_24Hours(t);
                break;
        }

        chart.getTeams();
    }

    const getAllTabsWithCards = async () => {
        const [dashboard, phonebook, settings] = await Promise.all([
            import("../../data/dashboard.js"),
            import("../../data/phonebook.js"),
            import("../../data/settings.js")
        ])
        
        const tabs = await dashboard.getTabs();
        
        await Promise.all(tabs.map(async (tab, index, receive) => {
            const cards = await dashboard.getCardsOfTab(tab.id);
            tab['cards'] = cards;

            await Promise.all(cards.map(async (card, index, receive) => {
                const params = await dashboard.getParamsOfCard(card.id);
                card['parameters'] = params;

                await Promise.all(params.map(async (param, index, receive) => {
                    if(param.parameter === 'employeeid'){
                        const employee = await phonebook.getIntPhonebook(param.value);

                        if(employee && employee.length > 0){
                          card['contact'] = employee[0];  
                          const device = await phonebook.getDeviceofUser(employee[0].deviceid);
                          employee[0]['devicedata'] = device;
                        }
                    }

                    if(param.parameter === 'roomid'){
                        const room = await settings.getRoom(param.value);
                        card['alarmsettings'] = room;
                    }
                }))
            }))
        }))

        setTabscards(tabs);
        setCurrenttab(tabs && tabs.length > 0 ? tabs[0].id : 0);
    }

    const menuShow = () => {
        setShowMenu(true);
    }

    const menuHide = () => {
        setShowMenu(false);
    }

    const setAlarm = (event) => {
        var messageid = event.target.dataset.message;
        var object = event.target.dataset.object;
        var value = event.target.dataset.value;

        roomAlarm(object, messageid, value);

        if (event.target.dataset.value === 'Enable') {
            event.target.dataset.value = 'Disable';
            event.target.className = 'alarm-icon alarmon';
        } else {
            event.target.dataset.value = 'Enable';
            event.target.className = 'alarm-icon alarmoff';
        }
    }

    fetchData(props.router.location.hash ? props.router.location.hash.split("#")[1] : 'day');

    const filterData = (period) => {
        fetchData(period);
        menuHide();
    }

    useEffect(() => {
        getAllTabsWithCards();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <i className="dash_view fas fa-ellipsis-v" onClick={() => menuShow()}></i>

                {showMenu && (
                    <div className="dash_view_options">
                        <span>{t('graphs')}</span>
                        <ul className="alarms_filters">
                            <Link to='/#day' style={{ color: '#000000', textDecoration: 'none' }}><li onClick={() => filterData('day')} className={`btn btn-alarms-filter btn-24hours ${props.router.location.hash.split("#")[1] === 'day' ? 'current' : ''}`}>{t('24hours')}</li></Link>
                            <Link to='/#week' style={{ color: '#000000', textDecoration: 'none' }}><li onClick={() => filterData('week')} className={`btn btn-alarms-filter btn-7days ${props.router.location.hash.split("#")[1] === 'week' ? 'current' : ''}`}>{t('7days')}</li></Link>
                        </ul>

                        <span>{t('settings')}</span>
                        <ul>
                            <li><Link to='/editor' style={{ color: '#000000', textDecoration: 'none' }}>{t('editdashboard')}</Link></li>
                        </ul>
                    </div>
                )}
 
                <div className="tabs dashboard ">
                <h1>Dashboard</h1>
                    <Tabs selectedIndex={tabindex} onSelect={index => {
                        setTabindex(index);
                        setCurrenttab(tabscards[index].id);
                    }}>
                    <TabList>
                        {tabscards.map((tab, i) => {
                            return (
                                <Tab data-id={tab.id}>
                                    {tab.tabname}
                                </Tab>
                            )
                        })}
                    </TabList>

                    {tabscards.map((tab, i) => {
                        return (
                        <TabPanel>
                            <div className="container-fluid dashboard">
                            <div className="grid row">

                                <div className="grid-sizer col-xs-6"></div>
                                    {tab.cards.map((card, i) => {
                                        var alarmClass = '';

                                        var status = {
                                            alarmred: 0,
                                            alarmorange: 0,
                                            status: t('inbathroom'),
                                            border: 'solid 5px rgb(92, 184, 92)',
                                            time: '00 ' + t('hours') + ' 01 ' + t('minutes'),
                                            color: '#FF0000',
                                            icon: 'inbathroom.png',
                                            warning: t('lastcriticalincident') + ': fald - 2023-11-03 14:00',
                                            warningicon: 'fas fa-exclamation-triangle'
                                        }

                                        return (
                                            <div key={i} data-cardid={card.id} className={`col-md-${card.cardsize}`}>
                                                <div className="grid-item-content">
                                                    {card.parameters[0].parameter == 'roomid' ? 
                                                        <VirtualRoundCard roomid={card.parameters[0].value} /> : 
                                                        
                                                        <div className="card" style={{ borderLeft: card.parameters[0].parameter == 'roomid' ? status.border : '' }}>
                                                            <h2 style={{ display: (card.cardname === 'Tips' ? 'none' : 'block') }} className={alarmClass}>{card.cardname}</h2>

                                                            <div className="card-body">
                                                                {card.parameters.map((param, i) => {
                                                                    var AlarmsArray = [];

                                                                    if (param.parameter === 'overviewid' && param.value === "1") {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <canvas className="line-graph" id="alarms" width="400" height="200"></canvas>
                                                                            </React.Fragment>
                                                                        )
                                                                    }

                                                                    else if (param.parameter === 'overviewid' && param.value === "2") {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <canvas className="line-graph" id="alarmstop" width="400"></canvas>
                                                                                <div id="legend" className="legend-day"></div>
                                                                            </React.Fragment>
                                                                        )
                                                                    }

                                                                    else if (param.parameter === 'overviewid' && param.value === "3") {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <canvas className="line-graph" id="rooms" width="400" height="200"></canvas>
                                                                            </React.Fragment>
                                                                        )
                                                                    }

                                                                    else if (param.parameter === 'overviewid' && param.value === "4") {
                                                                        return (
                                                                            <React.Fragment>
                                                                                <canvas id="team" width="400"></canvas>
                                                                            </React.Fragment>
                                                                        )
                                                                    }

                                                                    else if (param.parameter === 'tips' && param.value === "5") {
                                                                        return (
                                                                            <TipsBlock />
                                                                        );
                                                                    }

                                                                    // else if (param.parameter === 'roomid') {
                                                                    //     AlarmsArray = [
                                                                    //       {
                                                                    //         'id': 255,
                                                                    //         'Name': 'AlarmDoorOpen',
                                                                    //         'Icon': 'icon_23.png',
                                                                    //         'Message': 250,
                                                                    //         'Value': card.alarmsettings[0] ? card.alarmsettings[0].statusdooralarm : ''
                                                                    //       },
                                                                    //       {
                                                                    //         'id': 259,
                                                                    //         'Name': 'AlarmOutOfBed',
                                                                    //         'Icon': 'icon_46.png',
                                                                    //         'Message': 245,
                                                                    //         'Value': card.alarmsettings[0] ? card.alarmsettings[0].statusbedalarm : ''
                                                                    //       },
                                                                    //       {
                                                                    //         'id': 264,
                                                                    //         'Name': 'AlarmToilet',
                                                                    //         'Icon': 'icon_51.png',
                                                                    //         'Message': 264,
                                                                    //         'Value': card.alarmsettings[0] ? card.alarmsettings[0].statuswcalarm : ''
                                                                    //       },
                                                                    //       {
                                                                    //         'id': 321,
                                                                    //         'Name': 'AlarmBath',
                                                                    //         'Icon': 'icon_74.png',
                                                                    //         'Message': 312,
                                                                    //         'Value': card.alarmsettings[0] ? card.alarmsettings[0].statusbathalarm : ''
                                                                    //       },
                                                                    //       {
                                                                    //         'id': 322,
                                                                    //         'Name': 'AlarmIndringer',
                                                                    //         'Icon': 'icon_26.png',
                                                                    //         'Message': 318,
                                                                    //         'Value': card.alarmsettings[0] ? card.alarmsettings[0].statusindringeralarm : ''
                                                                    //       },
                                                                    //       {
                                                                    //         'id': 257,
                                                                    //         'Name': 'Acoustic',
                                                                    //         'Icon': 'icon_18.png',
                                                                    //         'Message': 236,
                                                                    //         'Value': card.alarmsettings[0] ? card.alarmsettings[0].soundlevel : ''
                                                                    //       },
                                                                    //     ]

                                                                    //     AlarmsArray.map((alarm, i) => {

                                                                    //       var alarmSwitch = '';
                                                                    //       var dataValue = '';

                                                                    //       if (alarm.Value > 0) {

                                                                    //         if (alarm.Value === 1) {
                                                                    //           alarmSwitch = 'alarmon';
                                                                    //           dataValue = 'Disable';
                                                                    //         }
                                                                    //         else {
                                                                    //           alarmSwitch = 'alarmoff';
                                                                    //           dataValue = 'Enable';
                                                                    //         }

                                                                    //       } else {
                                                                    //         alarmSwitch = 'alarmnotactive';
                                                                    //       }

                                                                    //       alarm['Switch'] = alarmSwitch;
                                                                    //       alarm['DataValue'] = dataValue;

                                                                    //     })

                                                                    //     return (
                                                                    //         <React.Fragment>
                                                                    //         {/* <div className='currentstatus' style={{ padding: "10px 15px" }}>{t('currentstatus')}: <span style={{ float: "right" }}>{t('inbed')}</span></div> */}
                                                                            
                                                                    //         {/* <VirtualRoundCard roomid={param.value} /> */}

                                                                    //         {/* <img src={`/images/icons/kepler/${status.icon}`} style={{ height: '100px', width:'auto', margin: 'auto', display: 'block' }}/>
                                                                    //         <div className='statustext' style={{ padding: "10px 15px 0 15px", fontSize: '16px', textAlign: 'center', marginTop: '20px', fontWeight: '600' }}><span>{status.status}</span></div>
                                                                    //         <div className='time' style={{ padding: "0 15px 10px 15px", fontSize: '16px', textAlign: 'center', marginTop: '5px' }}><span>{status.time}</span></div>
                                                                            
                                                                    //         <div className='criticalalert' style={{ padding: "10px 15px", textAlign: 'center' }}>
                                                                    //             <span class="criticalalarms" style={{ fontSize: '16px', marginRight: "10px" }}><i style={{ color: status.color }} className={status.warningicon}></i></span>

                                                                    //             {status.warning}
                                                                    //         </div> */}
                                                                    //       {/* <div className="room" style={{ background: '#f1f1f1', padding: '10px' }}>
                                                                    //         <div onClick={(event) => { setAlarm(event) }} className={`alarm-icon ${AlarmsArray[0].Switch}`} data-id={AlarmsArray[0].id} data-name={AlarmsArray[0].name} data-object={param.value} data-objectname={card.cardname} data-value={AlarmsArray[0].DataValue} data-message={AlarmsArray[0].Message} style={{ cursor: 'pointer', WebkitMaskBoxImage: 'url(images/icons/' + AlarmsArray[0].Icon + ')', MaskBoxImage: 'url(images/icons/' + AlarmsArray[0].Icon + ')' }}></div>
                                                                    //         <div onClick={(event) => { setAlarm(event) }} className={`alarm-icon ${AlarmsArray[1].Switch}`} data-id={AlarmsArray[1].id} data-name={AlarmsArray[1].name} data-object={param.value} data-objectname={card.cardname} data-value={AlarmsArray[1].DataValue} data-message={AlarmsArray[1].Message} style={{ cursor: 'pointer', WebkitMaskBoxImage: 'url(images/icons/' + AlarmsArray[1].Icon + ')', MaskBoxImage: 'url(images/icons/' + AlarmsArray[1].Icon + ')' }}></div>
                                                                    //         <div onClick={(event) => { setAlarm(event) }} className={`alarm-icon ${AlarmsArray[2].Switch}`} data-id={AlarmsArray[2].id} data-name={AlarmsArray[2].name} data-object={param.value} data-objectname={card.cardname} data-value={AlarmsArray[2].DataValue} data-message={AlarmsArray[2].Message} style={{ cursor: 'pointer', WebkitMaskBoxImage: 'url(images/icons/' + AlarmsArray[2].Icon + ')', MaskBoxImage: 'url(images/icons/' + AlarmsArray[2].Icon + ')' }}></div>
                                                                    //         <div onClick={(event) => { setAlarm(event) }} className={`alarm-icon ${AlarmsArray[3].Switch}`} data-id={AlarmsArray[3].id} data-name={AlarmsArray[3].name} data-object={param.value} data-objectname={card.cardname} data-value={AlarmsArray[3].DataValue} data-message={AlarmsArray[3].Message} style={{ cursor: 'pointer', WebkitMaskBoxImage: 'url(images/icons/' + AlarmsArray[3].Icon + ')', MaskBoxImage: 'url(images/icons/' + AlarmsArray[3].Icon + ')' }}></div>
                                                                    //         <div onClick={(event) => { setAlarm(event) }} className={`alarm-icon ${AlarmsArray[4].Switch}`} data-id={AlarmsArray[4].id} data-name={AlarmsArray[4].name} data-object={param.value} data-objectname={card.cardname} data-value={AlarmsArray[4].DataValue} data-message={AlarmsArray[4].Message} style={{ cursor: 'pointer', WebkitMaskBoxImage: 'url(images/icons/' + AlarmsArray[4].Icon + ')', MaskBoxImage: 'url(images/icons/' + AlarmsArray[3].Icon + ')' }}></div>
                                                                    //         <div onClick={(event) => { setAlarm(event) }} className={`alarm-icon ${AlarmsArray[5].Switch}`} data-id={AlarmsArray[5].id} data-name={AlarmsArray[5].name} data-object={param.value} data-objectname={card.cardname} data-value={AlarmsArray[5].DataValue} data-message={AlarmsArray[5].Message} style={{ cursor: 'pointer', WebkitMaskBoxImage: 'url(images/icons/' + AlarmsArray[5].Icon + ')', MaskBoxImage: 'url(images/icons/' + AlarmsArray[3].Icon + ')' }}></div>
                                                                    //         <input onClick={(event) => { changeVolume(event) }} data-name="volume" type="range" id="volume" data-object={param.value} data-objectname={card.cardname} className="volume" name="volume" step="1" min="0" max="100" value={card.alarmsettings[0] ? card.alarmsettings[0].soundlevel : ''} />
                                                                    //       </div> */}
                                                                    //       </React.Fragment>
                                                                    //     )
                                                                    // }

                                                                    else if (param.parameter === 'employeeid') {
                                                                        return (
                                                                            <div className='contact'>
                                                                                <i className="fas fa-user-circle"></i>
                                                                                <strong>{t('name')}:</strong> {card.contact ? card.contact.user : ''} <br />
                                                                                <strong>{t('device')}:</strong> {card.contact ? card.contact.device : ''} <br />
                                                                                <strong>{t('devicenumber')}:</strong> {card.contact ? card.contact.devicedata[0].value : ''}
                                                                            </div>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                    }

                                                    
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </TabPanel>)
                    })}
                </Tabs>
            </div>
        </div>
    </React.Fragment>
  );
}

export default withRouter(DashboardCustom);
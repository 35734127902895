import React, { Component, useState, useEffect } from 'react';
import withRouter from '../components/withRouter';
import { useTranslation } from 'react-i18next';

const TotalOpenAlarms = () => {
    const [countAlarms, setCountAlarms] = useState(0);
    const [alarmsRed, setAlarmsRed] = useState(0);
    const [alarmsOrange, setAlarmsOrange] = useState(0);
    const { t } = useTranslation();
    
    const getData = async () => {
        const alarms = await import("../data/alarms.js");
        const data = await alarms.getAlarms();

        var alarmsCounter = 0;
        var alarmsCounterRed = 0;
        var alarmsCounterOrange = 0;

        data.forEach(function (alarm) {
            alarmsCounter++;

            if (alarm.finish === '' && alarm.down !== '') {
                alarmsCounterOrange++;
            } else if (alarm.finish === '' && alarm.down === '') {
                alarmsCounterRed++
            }
        })

        setCountAlarms(alarmsCounter);
        setAlarmsRed(alarmsCounterRed);
        setAlarmsOrange(alarmsCounterOrange);
    }

    useEffect(() => {
        getData();
    }, [])

    return(
        <React.Fragment>
            <div className="performanceTotal" style={{ margin: '30px 55px 20px 55px' }}>
                <div className="row">
                    <div className="col-md-2 performanceBlock">
                        <i className="far fa-bell"></i>
                        <div className="block_title">{t('nrofalarms')}</div>
                        <div className="block_value">{countAlarms}</div>
                    </div>
                    <div className="col-md-2 performanceBlock">
                        <i className="far fa-bell" style={{ background: '#ff0000' }}></i>
                        <div className="block_title">{t('nothandled')}</div>
                        <div className="block_value">{alarmsRed}</div>
                    </div>
                    <div className="col-md-2 performanceBlock">
                        <i className="far fa-bell" style={{ background: 'orange' }}></i>
                        <div className="block_title">{t('pending')}</div>
                        <div className="block_value">{alarmsOrange}</div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(TotalOpenAlarms);
import React, { Component, useEffect } from 'react';
import './index.css';
import Dashboard from './modules/Dashboard/Dashboard';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';

// MBA Components
import Status from './mba/Status.js';
import ResponseRules from './mba/ResponseRules';
import ResponseRulesDetail from './mba/ResponseRulesDetail';
import Clusters from './mba/Clusters';
import MBADevices from './mba/Devices';
import Messages from './mba/Messages';
import MBALog from './mba/Log';
import StatusDetail from './mba/StatusDetail';
import TimeTables from './mba/Timetables';

// Careapp interface Components
import NotFound from './components/NotFound.js';
import Login from './components/Login/Login';
import OpenAlarms from './modules/Dashboard/OpenAlarms';
import HistoryAlarms from './modules/Dashboard/HistoryAlarms';
import AlarmDetail from './modules/Dashboard/AlarmDetail';
import Employees from './modules/Dashboard/Employees';
import EmployeesTeam from './modules/Dashboard/EmployeesTeam';
import ReportHistoryAlarmsTech from './modules/Reports/HistoryAlarmsTech';
import ReportHistoryAlarms from './modules/Reports/HistoryAlarms';
import ReportHistoryAlarmDetails from './modules/Reports/HistoryAlarmDetails';
import ReportHistoryAlarmTechDetails from './modules/Reports/HistoryAlarmTechDetails';
import ReportAlarmLogging from './modules/Reports/AlarmLogging';
import ReportEmployees from './modules/Reports/Employees';
import Roomunits from './modules/Reports/Roomunits';
import ReportWelfare from './modules/Reports/Welfare';
import ReportReceives from './modules/Reports/Receives';
import AlarmPerformance from './modules/Reports/AlarmPerformance';
import PhonebookInternal from './modules/Phonebook/PhonebookInternal';
import PhonebookExternal from './modules/Phonebook/PhonebookExternal';

// Manage modules
import Organisation from './manage/Organisation';
import Settings from './manage/Settings';
import SettingsVideo from './manage/Video';
import SettingsRooms from './manage/Rooms';
import SettingsTransmitters from './manage/Transmitters';
import SettingsRoomDetails from './manage/RoomDetails';
import OrganisationItems from './manage/OrganisationItems';
import DeviceTypes from './manage/DeviceTypes';
import Teams from './manage/Teams';
import TeamRoutes from './manage/Routes';
import TeamRouteDetails from './manage/RouteDetails';
import WelfareSettings from './manage/WelfareSettings.js';
import OrganisationDetails from './manage/OrganisationDetails';
import DeviceDetails from './manage/DeviceDetails';
import Devices from './manage/Devices';
import TeamDetails from './manage/TeamDetails';
import WelfareDetails from './manage/WelfareDetails';
import VitualTourSettings from './manage/VirtualTourSettings'
import Modules from './manage/Modules';
import ModuleDetail from './manage/ModuleDetail';
import Logging from './manage/Logging';
import Permissions from './manage/Permissions';
import PermissionsDetail from './manage/PermissionsDetail';
import ModulesCategories from './manage/ModulesCategories';
import Tracking from './manage/Tracking';
import TrackingDetail from './manage/TrackingDetail';
import Languages from './manage/Languages';
import LanguageDetails from './manage/LanguageDetails';
import NightNurseDetail from './manage/NightNurseDetail';
import RFID from './manage/RFID';
import Upload from './manage/Upload';
import Tips from './manage/Tips';
import NightNurse from './manage/NightNurse';

import VirtualTour from './modules/Dashboard/VirtualTour';
import BI from './components/BI';
import Hikvision from './modules/Hikvision';
import NoAccess from './components/NoAccess';
import NeckStatus from './modules/Reports/StatusNeck';
import Heartbeats from './modules/Reports/Heartbeats';
import Notifications from './manage/Notifications.js';

import Editor from './components/Dashboard/Editor';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import SiebarManage from './manage/components/Sidebar.js';

import ActivityReport from './modules/Reports/ActivityReport';

import EmployeeDash from './modules/Reports/EmployeeDash';
import ObjectStatus from './manage/ObjectStatus';
import EmployeeDashList from './modules/Reports/EmployeeDashList';

import { checkRefreshToken, getToken } from './Functions';

import ResponseRulesAdd from './mba/ResponseRulesAdd';
import MessageDetail from './mba/MessageDetail';
import TimetablesDetail from './mba/TimetablesDetail';

import i18next from 'i18next';
import VirtualRound from './modules/Dashboard/VirtualRound';
import RoomInsights from './modules/Reports/RoomInsights';
import RoomInsightsList from './modules/Reports/RoomInsightsList';
import Templates from './mba/Templates.js';
import TemplateDetails from './mba/TemplateDetails.js';

import PhonebookDetails from './modules/Phonebook/PhonebookDetails.js';
import TipsDetails from './manage/TipsDetails.js';

const App = () => {

  const getLanguage = async (userid) => {
    const organisation = await import("./data/organisation");
    const getUserConfig = await organisation.getConfig(userid);
    var countrycode = 'en';

    getUserConfig.forEach(conf => {
      if(conf.name === "Language"){
        countrycode = conf.value;
      }
    })

    localStorage.setItem('Language', countrycode);
    i18next.changeLanguage(countrycode);
  }

  useEffect(() => {
    const role = localStorage.getItem("Role");
    const rolesToCheck = ["1", "2", "5"];
    const token = localStorage.getItem('nightnursetoken');
    
    if ((rolesToCheck.includes(role)) && (!token || token === '')) {
        getToken();
    }
  
    setInterval(() => {
        if (rolesToCheck.includes(role)) {
            checkRefreshToken();
        }
    }, 1000)

    if(localStorage.getItem("Role") == "2" && !localStorage.getItem('nightnursetoken') || (localStorage.getItem('nightnursetoken') && localStorage.getItem('nightnursetoken') === '')){
      getToken();
    }

    if(localStorage.getItem("id_token")){
      getLanguage(localStorage.getItem('UserID'));
    }
  }, [])

  var userRole = localStorage.getItem("Role");
    var url = window.location.href.split("/");

    if (userRole == "1" && url[3] == "manage") {
      window.location.href = "/no-access";
    } else if (userRole == "3") {
      if ((url[3] == "manage" && url[4] != "welfaresettings") || (url[3] == "reports" && url[4] !== "welfare") || (url[3] == "settings" && userRole != "2")) {
        window.location.href = "/no-access";
      }
    } else {
      if(userRole !== "5"){
        if ((url[3] == "manage" && userRole != "2") || (url[3] == "settings" && userRole != "2")) {
          window.location.href = "/no-access";
        }
        if (url[3] == "docs" && url[4] == "bi" && userRole != "2" && url[3] == "docs" && url[4] == "bi" && userRole != "1") {
          window.location.href = "/no-access";
        }
      }
    }

    return (
      <div className="App">
        <Router>
          <React.Fragment>
            { localStorage.getItem('id_token') ? <Header /> : ''}
            <div>
              { localStorage.getItem('id_token') && window.location.href.split("/")[3] !== "manage" ? <Sidebar /> : <SiebarManage /> }
              <div className="content">
                <Routes>
                  {localStorage.getItem('id_token') && localStorage.getItem('id_token') !== '' ? <Route exact="true" path="/" element={<Dashboard />} /> : <Route exact="true" path="/" element={<Login />} />}
                  <Route exact="true" path="/mba-admin/status" element={<Status />} />
                  <Route exact="true" path="/mba-admin/status/:id" element={<StatusDetail />} />
                  <Route exact="true" path="/mba-admin/responserules" element={<ResponseRules />} />
                  <Route exact="true" path="/mba-admin/responserules/:id" element={<ResponseRulesDetail />} />
                  <Route exact="true" path="/mba-admin/responserules/insert" element={<ResponseRulesAdd />} />
                  <Route exact="true" path="/mba-admin/responserules/clusters" element={<Clusters />} />
                  <Route exact="true" path="/mba-admin/devices" element={<MBADevices />} />
                  <Route exact="true" path="/mba-admin/messages" element={<Messages />} />
                  <Route exact="true" path="/mba-admin/messages/:id" element={<MessageDetail />} />
                  <Route exact="true" path="/mba-admin/log/:date" element={<MBALog />} />
                  <Route exact="true" path="/mba-admin/log/:date/:date2" element={<MBALog />} />
                  <Route exact="true" path="/mba-admin/timetables" element={<TimeTables />} />
                  <Route exact="true" path="/mba-admin/timetables/:id" element={<TimetablesDetail />} />
                  <Route exact="true" path="/mba-admin/responserules/templates" element={<Templates />} />
                  <Route exact="true" path="/mba-admin/responserules/templates/:id" element={<TemplateDetails />} />

                  <Route exact="true" path="/manage/objectstatusses" element={<ObjectStatus />} />
                  <Route exact="true" path="/manage/organisation/upload" element={<Upload />} />
                  <Route exact="true" path="/manage/organisation/list/all" element={<Organisation />} />
                  <Route exact="true" path="/manage/organisation/object/:id" element={<OrganisationItems />} />
                  <Route exact="true" path="/manage/organisation/details/:id/:objectid" element={<OrganisationDetails />} />
                  <Route exact="true" path="/manage/devices/list/all" element={<DeviceTypes />} />
                  <Route exact="true" path="/manage/device/:id" element={<DeviceDetails />} />
                  <Route exact="true" path="/manage/devices/object/:id" element={<Devices />} />
                  <Route exact="true" path="/manage/teams" element={<Teams />} />
                  <Route exact="true" path="/manage/team/:id" element={<TeamDetails />} />
                  <Route exact="true" path="/manage/teamroutes" element={<TeamRoutes />} />
                  <Route exact="true" path="/manage/teamroute/:id" element={<TeamRouteDetails />} />
                  <Route exact="true" path="/manage/tips" element={<Tips />} />
                  <Route exact="true" path="/manage/tips/:id" element={<TipsDetails />} />
                  <Route exact="true" path="/manage/rfid" element={<RFID />} />
                  <Route exact="true" path="/manage/welfaresettings" element={<WelfareSettings />} />
                  <Route exact="true" path="/manage/welfaresettings/:id" element={<WelfareDetails />} />
                  <Route exact="true" path="/manage/nightnurse" element={<NightNurse />} />
                  <Route exact="true" path="/manage/nightnurse/:id" element={<NightNurseDetail />} />
                  <Route exact="true" path="/manage/logging" element={<Logging />} />
                  <Route exact="true" path="/manage/languages" element={<Languages />} />
                  <Route exact="true" path="/manage/languages/:id" element={<LanguageDetails />} />
                  <Route exact="true" path="/manage/video" element={<SettingsVideo />} />
                  <Route exact="true" path="/manage/settings" element={<Settings />} />
                  <Route exact="true" path="/manage/rooms" element={<SettingsRooms />} />
                  <Route exact="true" path="/manage/transmitters" element={<SettingsTransmitters />} />
                  <Route exact="true" path="/manage/virtualtour/settings" element={<VitualTourSettings />} />
                  <Route exact="true" path="/manage/rooms/:id" element={<SettingsRoomDetails />} />
                  <Route exact="true" path="/manage/modules" element={<Modules />} />
                  <Route exact="true" path="/manage/modules/categories" element={<ModulesCategories />} />
                  <Route exact="true" path="/manage/modules/:id" element={<ModuleDetail />} />
                  <Route exact="true" path="/manage/permissions" element={<Permissions />} />
                  <Route exact="true" path="/manage/permissions/role/:id" element={<PermissionsDetail />} />
                  <Route exact="true" path="/manage/tracking" element={<Tracking />} />
                  <Route exact="true" path="/manage/tracking/:mac" element={<TrackingDetail />} />
                  <Route exact="true" path="/manage/notifications" element={<Notifications />} />
                  
                  <Route exact="true" path="/editor" element={<Editor />} />
                  <Route exact="true" path="/hikvision" element={<Hikvision />} />
                  <Route exact="true" path="/alarms/open" element={<OpenAlarms />} />
                  <Route exact="true" path="/alarms/history" element={<HistoryAlarms />} />
                  <Route exact="true" path="/alarm/:id" element={<AlarmDetail />} />
                  <Route exact="true" path="/employees" element={<Employees />} />
                  <Route exact="true" path="/employees/team/:id" element={<EmployeesTeam />} />
                  <Route exact="true" path="/virtualtour" element={<VirtualTour />} />
                  <Route exact="true" path="/virtualround" element={<VirtualRound />} />
                  <Route exact="true" path="/reports/historyalarmstech/:reportdate" element={<ReportHistoryAlarmsTech />} />
                  <Route exact="true" path="/reports/historyalarmstech/alarm/:id" element={<ReportHistoryAlarmTechDetails />} />
                  <Route exact="true" path="/reports/historyalarms/:reportdate" element={<ReportHistoryAlarms />} />
                  <Route exact="true" path="/reports/historyalarms/alarm/:id" element={<ReportHistoryAlarmDetails />} />
                  <Route exact="true" path="/reports/performance/:reportdate" element={<AlarmPerformance />} />
                  <Route exact="true" path="/reports/performance/:reportdate/:reportdate2" element={<AlarmPerformance />} />
                  <Route exact="true" path="/reports/alarmlogging/:reportdate" element={<ReportAlarmLogging />} />
                  <Route exact="true" path="/reports/activity/:reportdate" element={<ActivityReport />} />
                  <Route exact="true" path="/reports/employees/:reportdate" element={<ReportEmployees />} />
                  <Route exact="true" path="/reports/employeedash/" element={<EmployeeDashList />} />
                  <Route exact="true" path="/reports/employeedash/:id/:reportdate" element={<EmployeeDash />} />
                  <Route exact="true" path="/reports/roomunits/" element={<Roomunits />} />
                  <Route exact="true" path="/reports/neckstatus/" element={<NeckStatus />} />
                  <Route exact="true" path="/reports/welfare/:reportdate" element={<ReportWelfare />} />
                  <Route exact="true" path="/reports/heartbeats" element={<Heartbeats />} />
                  <Route exact="true" path="/reports/roominsights" element={<RoomInsightsList />} />
                  <Route exact="true" path="/reports/roominsights/:id/:reportdate" element={<RoomInsights />} />
                  <Route exact="true" path="/manage/reports/receives/:reportdate" element={<ReportReceives />} />
                  <Route exact="true" path="/phonebook/internal" element={<PhonebookInternal />} />
                  <Route exact="true" path="/phonebook/external" element={<PhonebookExternal />} />
                  <Route exact="true" path="/phonebook/contact/:id" element={<PhonebookDetails />} />
                  
                  <Route exact="true" path="/login" element={<Login />} />
                  <Route exact="true" path="/docs/bi" element={<BI />} />
                  <Route exact="true" path="/no-access" element={<NoAccess />} />
                  <Route exact="true" path="/not-found" element={<NotFound />} />
                  <Route element={<NotFound />} />
                </Routes>
              </div>
            </div>
          </React.Fragment>
        </Router>
      </div>
    );
}

export default App;
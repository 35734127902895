import * as moment from 'moment';
import { Authenticate } from "./data/nightnurse";
// import 'datatables.net';
import DataTable from 'datatables.net-dt';
import 'datatables.net-buttons';
import cookies from 'js-cookie';
import { SvelteGantt, SvelteGanttTable, MomentSvelteGanttDateAdapter, SvelteGanttDependencies } from 'svelte-gantt';
const $ = require('jquery');
moment.locale('nl');

export const getData = (url, method, headers) => {
    return fetch(url, {
        "method": method,
        "headers": headers
    });
}

export const initDataTable = (init) => {
    const currentLanguageCode = cookies.get('i18next') || 'en';
    var url = '';

    if(currentLanguageCode === 'nl'){
        url = "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json";
    }else if(currentLanguageCode === 'dk'){
        url = "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Danish.json";
    }else{
        url = "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/English.json";
    }

    let table = new DataTable('#DataTable');
    table.destroy();  
    setTimeout(() => {  
        table = new DataTable('#DataTable', {
            "language": {
                "url": url
            },
            "aaSorting": []
        });
    }, 500)
}

export const initDataTable2 = (init) => {
    let table = new DataTable('#DataTable.datatable2');
    table.destroy();  
    setTimeout(() => {  
        table = new DataTable('#DataTable.datatable2', {
            "language": {
                "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
            },
            "aaSorting": []
        });
    }, 500)
}

export const initDataTable3 = (init) => {
    let table = new DataTable('#DataTable.datatable3');
    table.destroy();  
    setTimeout(() => {  
        table = new DataTable('#DataTable.datatable3', {
            "language": {
                "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Dutch.json"
            },
            "aaSorting": []
        });
    }, 500)
}

export const filterDataTable = (item, column, value, exact) => {
    sessionStorage.removeItem(item);
    sessionStorage.setItem(item, value);

    let table = new DataTable('#DataTable');

    if (exact === true) {
        if(value != 0){
            console.log("not 0");
            $('#DataTable').DataTable().column(column).search("(^" + value + "$)", true, false).draw();
        }else{
            table.destroy();  
            $('#DataTable').DataTable();
        }
    } else {
        $('#DataTable').DataTable().column(column).search(value).draw();
    }
}

export const initCams = () => {
    var $rand = 1;

    $('.carousel-item:first-child').addClass('active');

    var Items = $('.carousel-item').length;

    for (var i = 0; i < Items; i++) {
        var CameraHost = document.getElementsByClassName("carousel-item")[i].getAttribute("data-host");
        setInterval(function () {
            $rand = $rand + 1
            $('.CamPic2').attr('src', 'http://' + CameraHost + '/record/current.jpg?rand=' + $rand);
        }, 100);
    }
}

export const bulkSelect = (event, array) => {

    var id = event.target.parentElement.getAttribute('data-id');
    var name = event.target.parentElement.getAttribute('data-name');

    if (event.target.checked === true) {
        array.push({
            "id": id,
            "name": name
        })
    } else {
        for (var i = 0; i < array.length; i++) {
            if (array[i] === id) {
                array.splice(i, 1);
            }
        }
    }

    if (array.length > 0) {
        $('.bulk').show()
    }
}

export const clickedRow = (event, url) => {
    var actionsClasses = ["bulkSelect", "btn-delete", "WelfareAlarmtogBtn", "slider", "round"];
    if (!actionsClasses.some(className => event.target.classList.contains(className))) {
        window.location.href = url;
    }
}

export const confirmDelete = (text, yesCallback, noCallback) => {
    $('.modalTitle').html(text);
    $('#modal_dialog').show();

    $('#btnYes').on('click', function () {
        $('#modal_dialog').hide();
        if (yesCallback) {
            yesCallback();
        }
        console.log("YES");
    });

    $('#btnNo').on('click', function () {
        $('#modal_dialog').hide();
        if (noCallback) {
            noCallback();
        }
    });

    $('.delclose').on('click', function () {
        $('#modal_dialog').hide();
    })
}

// export const fillDataTableFilters = (filter, value) => {
//     if (value && !filter.find("option:contains('" + value + "')").length) {
//         var option = new Option(value, value);
//         option.innerHTML = value;
//         filter[0].appendChild(option);
//     }
// }

export const fillDataTableFilters = (filter, value) => {
  if (value && ![...filter.options].some((option) => option.textContent.includes(value))) {
    const option = new Option(value, value);
    option.innerHTML = value;
    filter.appendChild(option);
  }
};

export const calcAlarmDuration = (alarm) => {
    var timestampUpTriggered = moment(alarm.uptriggered).unix();
    var dateOfToday = moment().unix();
    var timestampFinishTriggered;
    var diff;
    var hours, minutes, seconds;

    if (alarm.canceltriggered) {
        timestampFinishTriggered = moment(alarm.canceltriggered).unix();
    } else if (alarm.finishtriggered) {
        timestampFinishTriggered = moment(alarm.finishtriggered).unix();
    } else {
        timestampFinishTriggered = dateOfToday;
    }

    diff = timestampFinishTriggered - timestampUpTriggered;

    if (diff > 0) {

        hours = diff / 3600 % 60;
        minutes = diff / 60 % 60;
        seconds = diff % 60;

        if (hours < "1") {
            hours = "0";
        } else {
            hours = hours.toFixed(0);
        }

        if (minutes < "10") {
            minutes = '0' + minutes.toFixed(0);
        } else {
            minutes = minutes.toFixed(0);
        }

        if (seconds < "10") {
            seconds = '0' + seconds.toFixed(0);
        } else {
            seconds = seconds.toFixed(0);
        }

        return hours + ":" + minutes + ":" + seconds
    }
}

export const formatDate = (date, addHours) => {
    if(date){
        Date.prototype.addHours = function(h) {
            this.setTime(this.getTime() + (h*60*60*1000));
            return this;
        }
    
        if(addHours){
            date = new Date(date).addHours(1);
        }
    
        if((date.getMonth() + 1) < 10){
            var currentMonth = "0" + (date.getMonth() + 1)
        }else{
            currentMonth = (date.getMonth() + 1);
        }
    
        if(date.getDate() < 10){
            var currentDay = "0" + date.getDate();
        }else{
            currentDay = date.getDate();
        }
    
        if(date.getHours() < 10){
            var currentHour = "0" + date.getHours();
        }else{
            currentHour = date.getHours();
        }
    
        if(date.getMinutes() < 10){
            var currentMinutes = "0" + date.getMinutes();
        }else{
            currentMinutes = date.getMinutes();
        }
    
        if(date.getSeconds() < 10){
            var currentSeconds = "0" + date.getSeconds();
        }else{
            currentSeconds = date.getSeconds();
        }
        return date.getFullYear() + '-' + currentMonth + '-' + currentDay + ' ' + currentHour + ':' + currentMinutes + ':' + currentSeconds;
    }
}

export const getToken = () => {
    Authenticate().then(data => {
        var now = formatDate(new Date());
        localStorage.setItem("nightnursetokenDate", now);

        var expireDate = formatDate(new Date(), 1);
        localStorage.setItem("nightnursetokenExpire", expireDate);
        localStorage.setItem("nightnursetoken", data.access_token);
    })
};


export const checkRefreshToken = () => {
    var currentTime = new Date().getTime();
    var expireTime = new Date(localStorage.getItem('nightnursetokenExpire')).getTime();
    var newExpireTime = expireTime - (5 * 60 * 1000); // minus 5 minutes

    if(localStorage.getItem('nightnursetokenDate') && localStorage.getItem('nightnursetokenExpire')){
        if(currentTime > newExpireTime){
            sessionStorage.removeItem('nightnursetoken');
            sessionStorage.removeItem('nightnursetokenDate');
            sessionStorage.removeItem('nightnursetokenExpire');

            getToken();
        }
    }
}

export const crudAlertControl = (action, data) => {
    if(action === 'add'){
        $('.alert-not-selected').css('display', 'none');
        $('.alert-add').css('display', 'none');
        $('.alert-delete').css('display', 'none');
        $('.alert-danger').css('display', 'none');

        if(data === "OK" || data.length > 0){
            $('.alert-danger').css('display', 'none');
            $('.alert-add').fadeIn(1000);
        }else{
            $('.alert-danger').fadeIn(1000);
            $('.alert-add').css('display', 'none');
        }
    }else if(action === 'delete'){
        $('.alert-not-selected').css('display', 'none');
        $('.alert-add').css('display', 'none');
        $('.alert-danger').css('display', 'none');
        $('.alert-delete').css('display', 'none');

        if(data === "OK"){
            $('.alert-danger').css('display', 'none');
            $('.alert-delete').fadeIn(1000);
        }else{
            $('.alert-danger').fadeIn(1000);
            $('.alert-delete').css('display', 'none');
        }
    }else if (action === 'update'){
        $('.alert-update').css('display', 'none');
        $('.alert-danger').css('display', 'none');
        $('.alert-not-selected').css('display', 'none');

        if (data === "OK" || data.length > 0) {
            $('.alert-danger').css('display', 'none');
            $('.alert-update').css('display', 'block');
        } else {
            $('.alert-danger').css('display', 'block');
            $('.alert-update').css('display', 'none');
        }
    }else if (action === 'not selected'){
        $('.alert-update').css('display', 'none');
        $('.alert-danger').css('display', 'none');
        $('.alert-not-selected').css('display', 'block');
    }
}

export const locationAlert = () => {
    if(!localStorage.getItem('currentLocation') || localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') === ''){
        $('.msg_no_location').fadeIn(1000);
        $('.loc_content').hide();
    }else{
        $('.msg_no_location').hide();
        $('.loc_content').fadeIn(1000);
    }
}

export const getAlarmsFromRoom = async (streamid, streamname, reportdate) => {
    var alarmcount = 0;
    const detectionsArray = [
        { id: 0, label: "NO_DETECTION" },
        { id: 1, label: "MAN_DOWN_DETECTION" },
        { id: 2, label: "SOEB_DETECTION" },
        { id: 3, label: "OUT_OF_BED_DETECTION" },
        { id: 4, label: "OUT_OF_ROOM_DETECTION" },
        { id: 5, label: "IN_BED_DETECTION" },
        { id: 6, label: "IN_BATHROOM_DETECTION" },
        { id: 7, label: "MISSING_BED_DETECTION" },
        { id: 8, label: "PERSON_VISIBLE_DETECTION" },
        { id: 9, label: "PERSON_GETTING_UP_FROM_CHAIR_DETECTION" },
        { id: 10, label: "PERSON_GETTING_UP_IN_BED_DETECTION" },
        { id: 11, label: "STAFF_ENTERING_ROOM_DETECTION" },
        { id: 12, label: "CAMERA_UNREACHABLE_DETECTION" },
        { id: 13, label: "HALLWAY_WANDER_DETECTION" }
    ];

    const nightnurse = await import("./data/nightnurse.js");
    const data = await nightnurse.getKeplerActivities(streamid, reportdate);

    console.log(data);

    if (data.camera !== streamname) {
        return [];
    }

    function convertTime(timestamp) {
        var date = timestamp.split(":")[0];
        var datetime = timestamp.split(":")[1];

        var hour = datetime.split("-")[0];
        var minutes = datetime.split("-")[1];

        return date + ' ' + hour + ":" + minutes;
    }

    var previousDate = '';
    var tasksarray = [];

    data.time_entries.map((task, index) => {
        task.Time = convertTime(task.Time);
        var nextDate = convertTime(data.time_entries[1].Time);

        if(!task.Time){
            return [];
        }

        task.Time = moment(new Date(task.Time)).format("YYYY-MM-DD HH:mm");
        previousDate = moment(new Date(previousDate)).format("YYYY-MM-DD HH:mm");
        nextDate = moment(new Date(nextDate)).format("YYYY-MM-DD HH:mm");

        alarmcount += 1;

        tasksarray.push({
            id: alarmcount,
            resourceId: streamname,
            label: detectionsArray.find((alarm) => alarm.id === task.DetectionType)?.label ?? '',
            from: index == 0 ? moment(task.Time) : moment(previousDate),
            to: index == 0 ? moment(nextDate) : moment(task.Time),
            classes: 'ALARM_' + detectionsArray.find((alarm) => alarm.id === task.DetectionType)?.label ?? ''
        });

        previousDate = task.Time
    });

    return tasksarray;
}

export const getWeekAlarmsFromRoom = async (streamid, streamname, reportdate) => {
    var alarmcount = 0;
    const detectionsArray = [
        { id: 0, label: "NO_DETECTION" },
        { id: 1, label: "MAN_DOWN_DETECTION" },
        { id: 2, label: "SOEB_DETECTION" },
        { id: 3, label: "OUT_OF_BED_DETECTION" },
        { id: 4, label: "OUT_OF_ROOM_DETECTION" },
        { id: 5, label: "IN_BED_DETECTION" },
        { id: 6, label: "IN_BATHROOM_DETECTION" },
        { id: 7, label: "MISSING_BED_DETECTION" },
        { id: 8, label: "PERSON_VISIBLE_DETECTION" },
        { id: 9, label: "PERSON_GETTING_UP_FROM_CHAIR_DETECTION" },
        { id: 10, label: "PERSON_GETTING_UP_IN_BED_DETECTION" },
        { id: 11, label: "STAFF_ENTERING_ROOM_DETECTION" },
        { id: 12, label: "CAMERA_UNREACHABLE_DETECTION" },
        { id: 13, label: "HALLWAY_WANDER_DETECTION" }
    ];

    const nightnurse = await import("./data/nightnurse.js");
    const data = await nightnurse.getKeplerWeekActivities(streamid, reportdate);

    function convertTime(timestamp) {
        var date = timestamp.split(":")[0];
        var datetime = timestamp.split(":")[1];

        var hour = datetime.split("-")[0];
        var minutes = datetime.split("-")[1];

        return date + ' ' + hour + ":" + minutes;
    }

    var previousDate = '';
    var weekarray = [];

    data.forEach((task, index) => {
        
        task.Time = convertTime(task.Time);
        var nextDate = convertTime(data[1].Time);

        if(!task.Time){
            return [];
        }

        task.Time = moment(new Date(task.Time)).format("YYYY-MM-DD HH:mm");
        var timeWithoutDate = moment(new Date(task.Time)).format(reportdate + " HH:mm");
        previousDate = moment(new Date(previousDate)).format("YYYY-MM-DD HH:mm");
        var prevTimeWithoutDate = moment(new Date(previousDate)).format(reportdate + " HH:mm");
        nextDate = moment(new Date(nextDate)).format("YYYY-MM-DD HH:mm");
        var nextTimeWithoutDate = moment(new Date(nextDate)).format(reportdate + " HH:mm");

        alarmcount += 1;

        weekarray.push({
            id: alarmcount,
            resourceId: moment(new Date(task.Time)).format("DD"),
            label: detectionsArray.find((alarm) => alarm.id === task.DetectionType)?.label ?? '',
            from: index == 0 ? moment(timeWithoutDate) : moment(prevTimeWithoutDate),
            to: index == 0 ? moment(nextTimeWithoutDate) : moment(timeWithoutDate),
            classes: 'ALARM_' + detectionsArray.find((alarm) => alarm.id === task.DetectionType)?.label ?? ''
        });

        previousDate = task.Time
    });

    return weekarray;
}

export const loadChart = async (getActivities, streamname, reportdate) => {
    document.getElementById('chart').innerHTML = '';

    var currentStart = moment(reportdate + " 00:00:00");
    var currentEnd = moment(reportdate + " 23:59:59");

    const data = {
        rows: [
            
            {
                id: streamname,
                label: streamname
            }
        ],
        tasks: getActivities,
        dependencies: []
    };

    console.log(data.rows);

    let options = {
        dateAdapter: new MomentSvelteGanttDateAdapter(moment),
        rows: data.rows,
        tasks: data.tasks,
        dependencies: data.dependencies,
        timeRanges: [],
        columnUnit: "hour",
        columnOffset: 7,
        magnetUnit: "hour",
        magnetOffset: 1,
        rowHeight: 52,
        rowPadding: 6,
        headers: [
            { unit: "hour", format: "H", sticky: true }
        ],
        fitWidth: true,
        from: currentStart,
        to: currentEnd,
        tableHeaders: [
            { title: '', property: "label", width: 140, type: "tree" }
        ],
        tableWidth: 240,
        ganttTableModules: [SvelteGanttTable],
        ganttBodyModules: [SvelteGanttDependencies]
    };

    await new SvelteGantt({
        target: document.getElementById('chart'),
        props: options
    });
}

export const loadWeekChart = (getActivities, streamname, reportdate) => {
    document.getElementById('chart').innerHTML = '';

    var currentStart = moment(reportdate + " 00:00:00");
    var currentEnd = moment(reportdate + " 23:59:59");

    const data = {
        rows: [
            
            {
                id: 15,
                label: "15"
            },
            {
                id: 16,
                label: "16"
            },
            {
                id: 17,
                label: "17"
            },
            {
                id: 18,
                label: "18"
            },
            {
                id: 19,
                label: "19"
            },
            {
                id: 20,
                label: "20"
            },
            {
                id: 21,
                label: "21"
            }
        ],
        tasks: getActivities
    };
    
    setTimeout(() => {
        let options = {
            dateAdapter: new MomentSvelteGanttDateAdapter(moment),
            rows: data.rows,
            tasks: data.tasks,
            dependencies: data.dependencies,
            columnUnit: "hour",
            columnOffset: 1,
            rowHeight: 52,
            rowPadding: 6,
            headers: [
                { unit: "hour", format: "H", sticky: true }
            ],
            fitWidth: true,
            from: currentStart,
            to: currentEnd,
            tableHeaders: [
                { title: '', property: "label", width: 50, type: "tree" }
            ],
            tableWidth: 50,
            ganttTableModules: [SvelteGanttTable],
            ganttBodyModules: [SvelteGanttDependencies]
        };
    
        new SvelteGantt({
            target: document.getElementById('chart'),
            props: options
        });
    }, 1000)
}

export const nightnurse_token_check = (roles) => {
    const role = localStorage.getItem("Role");
    const rolesToCheck = roles;
    const token = localStorage.getItem('nightnursetoken');
    
    if ((rolesToCheck.includes(role)) && (!token || token === '')) {
        getToken();
    }
    
    setInterval(() => {
        if (rolesToCheck.includes(role)) {
            checkRefreshToken();
        }
    }, 1000)
}
import decode from 'jwt-decode';
import Config from "../../config.json";

export default class AuthService {
    constructor(domain) {
        this.fetch = this.fetch.bind(this)
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    ADLogin(Name, idToken) {

        return this.fetch(Config.API_URL + `/mba/object/user`, {
            method: 'GET',
            headers: {
                "authorization": idToken,
                "name": Name
            }
        }).then(res => {
            this.setToken(res.token)
            localStorage.setItem('AD', true)
            localStorage.setItem('UserID', res.user.id);
            localStorage.setItem('CustomerID', res.user.customerid);
            localStorage.setItem('UserName', res.user.name);
            localStorage.setItem('GUID', res.user.guid);
            localStorage.setItem('Role', res.user.role);
            localStorage.setItem('CurrentRole', res.user.role);
            return Promise.resolve(res);
        }).then(res => {
            window.location.reload()
        })

    }

    getGraphInfo (accessToken){
        return fetch("https://graph.microsoft.com/v1.0/me", {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + accessToken,
              'Content-Type': 'application/json',
            },
          })
          .then(response => response.json())
          .then(data => {
            return data;
          });
    }

    login(username, password) {

        // Get a token
        return this.fetch(Config.API_URL + `/auth`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "username": username,
                "password": password
            }
        }).then(res => {
            if (res.user) {
                this.setToken(res.token)
                localStorage.setItem('UserID', res.user.id);
                localStorage.setItem('CustomerID', res.user.customerid);
                localStorage.setItem('UserName', res.user.name);
                localStorage.setItem('GUID', res.user.guid);
                localStorage.setItem('Role', res.user.role);
                return Promise.resolve(res);
            } else {
                return false;
            }
        }).catch(error => {
            return false;
        });
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken()
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isAD() {
        // Checks if there is a saved token and it's still valid
        const AD = localStorage.getItem('AD')
        return AD // handwaiving here
    }

    isVerified() {
        // Checks if there is a saved token and it's still valid
        const verified = this.getVerifyCode()

        if (verified === true) {
            return true;
        }
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.clear();
        sessionStorage.clear();
    }

    getProfile() {
        return decode(this.getToken());
    }

    setVerifyCode(code) {
        // Saves user token to localStorage
        localStorage.setItem('verify', code)
    }

    getVerifyCode() {
        return localStorage.getItem('verified')
    }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}
import Config from "../config.json";

export const getHistoryAlarms = (alarmid, reportdate) => {

  var url = '';
  if(alarmid && alarmid !== ''){
    if(alarmid.includes("N")){
      alarmid = alarmid.split("N")[1];
      url = Config.API_URL + `/mba/report/historyalarmtech/${alarmid}`;
    }else{
      url = Config.API_URL + `/mba/report/historyalarm/${alarmid}`;
    }
  }else{
    url = Config.API_URL + "/mba/report/historyalarm";
  }


  if(url){
    return fetch(url, {
      "method": "GET",
      "headers": {
        "Authorization": "Bearer " + localStorage.getItem('id_token'),
        "Content-Type": "application/x-www-form-urlencoded",
        "userid": localStorage.getItem('UserID'),
        "customerid": localStorage.getItem('CustomerID'),
        "reportdate": reportdate ? reportdate : '',
        "alarmid": alarmid ? alarmid : '',
        "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
      }
    })
      .then(response => response.json())
      .then(data => {
        return data;
      })
  }
}

export const getUsersOfAlarm = (alarmid, dateofalarm, teamid) => {
  return fetch(Config.API_URL + `/mba/report/historyalarm/${alarmid}/teams/users`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "alarmid": alarmid,
      "customerid": localStorage.getItem('CustomerID'),
      "datealarm": dateofalarm,
      "teamid": teamid
    }
  })
    .then(response => response.json())
    .then(users => {
      return users;
    })
}

export const getTeamsOfAlarms = (alarmid) => {
  return fetch(Config.API_URL + `/mba/report/historyalarm/${alarmid}/teams`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "alarmid": alarmid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const HistoryLog = (alarmid, objectalarmteamid) => {
  return fetch(Config.API_URL + `/mba/report/historyalarm/${alarmid}/log`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectalarmteamid": objectalarmteamid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getGraphData = (reportdate) => {
  return fetch(Config.API_URL + "/mba/report/historyalarm/date", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "reportdate": reportdate
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getSDStatus = () => {
  return fetch(Config.API_URL + "/mba/sdstatus/get/", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getNeckStatus = () => {
  return fetch(Config.API_URL + "/mba/neck/get/", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getReceives = (reportdate) => {
  return fetch(Config.API_URL + "/mba/report/receives", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "reportdate": reportdate
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getReceivesTeam = (objectalarmid) => {
  return fetch(Config.API_URL + "/mba/report/receives/team/", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectalarmteamid": objectalarmid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const ReceivesRecievedBy = (objectalarmteamid) => {
  return fetch(Config.API_URL + "/mba/report/receives/recievedby", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectalarmteamid": objectalarmteamid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getEmployees = (reportdate) => {
  return fetch(Config.API_URL + "/mba/report/employee", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "reportdate": reportdate,
      "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const teamsOfEmployee = (reportdate, logindate, logoutdate, employeeid) => {
  return fetch(Config.API_URL + `/mba/report/employee/${employeeid}/teams`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "employeeid": employeeid,
      "logindate": logindate,
      "logoutdate": logoutdate,
      "reportdate": reportdate
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getHeartBeats = () => {
  return fetch(Config.API_URL + `/mba/report/heartbeats`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getLogOfTeam = (teamid, alarmid) => {
  return fetch(Config.API_URL + `/mba/report/historyalarm/${alarmid}/getlog/teams/${teamid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "teamid": teamid,
      "alarmid": alarmid
    }
  })
    .then(response => response.json())
    .then(logs => {
      return logs
    })
}

export const getTeamsLogsOfAlarm = (alarmid) => {
  return fetch(Config.API_URL + `/mba/report/historyalarm/${alarmid}/getlog/teams`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "alarmid": alarmid
    }
  })
    .then(response => response.json())
    .then(log => {
      return log;
    })
}
import React, { Component, useEffect, useState } from 'react';
import './Settings.css';
import '../components/DataTables.css';
import withAuth from '../components/Login/withAuth';
import { initDataTable, fillDataTableFilters, filterDataTable } from "../Functions.js";
import { useNavigate } from 'react-router';
import LocationAlert from '../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const SettingsRooms = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [locations, setLocations] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloors] = useState([]);
  const [floorrooms, setFloorrooms] = useState([]);
  const [rooms, setRooms] = useState([]);

  const getData = async() => {
    const objects = await import("../data/objects");
    const locationsData = await objects.getLocations();
    const buildingsData = await objects.getBuildings();
    const floorsData = await objects.getFloors();
    const roomsData = await objects.getRooms();

    setLocations(locationsData);
    setBuildings(buildingsData);
    setFloors(floorsData);
    setFloorrooms(roomsData);
    initDataTable(); 
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <React.Fragment>
      <LocationAlert />

      <div className="loc_content">
      <h1>{t('rooms')}</h1>

        <div class="filters">
          {t('floor')}:
          <select id="floor" onChange={(event) => filterDataTable("roomsettings-floor", 1, event.target.value, false)}>
            <option data-id="0" value="">{t('select')}</option>
          </select>

          {t('building')}:
          <select id="building" onChange={(event) => filterDataTable("roomsettings-building", 2, event.target.value, false)}>
            <option data-id="0" value="">{t('select')}</option>
          </select>

          {t('location')}:
          <select id="location" onChange={(event) => filterDataTable("roomsettings-location", 3, event.target.value, false)}>
            <option data-id="0" value="">{t('select')}</option>
          </select>

          <div id="buttons"></div>
        </div>

        <div className="DataTable_Container">
          <table id="DataTable" class="table responsive nowrap" width="100%">
            <thead style={{ display: 'table-header-group', width: '100%' }}>
              <tr class="rowtitle">
                <th>{t('room')}</th>
                <th>{t('floor')}</th>
                <th>{t('building')}</th>
                <th>{t('location')}</th>
              </tr>
            </thead>
            <tbody>
              {floorrooms.map((room, i) => {
                  const floor = floors.find(floor => floor.floorid === room.relatedid);
                  const building = buildings.find(building => building.buildingid === floor?.relatedid);
                  const location = locations.find(location => location.locationid === building?.relatedid);

                  if (floor) {
                      fillDataTableFilters(document.getElementById('floor'), floor.floorname);
                  }
                  if (building) {
                      fillDataTableFilters(document.getElementById('building'), building.buildingname);
                  }
                  if (location) {
                      fillDataTableFilters(document.getElementById('location'), location.locationname);
                  }

                return (
                  <tr style={{ cursor: 'pointer' }} onClick={() => { navigate(`/manage/rooms/${room.roomid}`) }}>
                    <td>{room && room.roomname ? room.roomname : ''}</td>
                    <td>{floor && floor.floorname ? floor.floorname : ''}</td>
                    <td>{building && building.buildingname ? building.buildingname : ''}</td>
                    <td>{location && location.locationname ? location.locationname : ''}</td>
                  </tr>)
              })}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withAuth(SettingsRooms);
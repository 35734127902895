import React, { Component, useEffect, useState } from 'react';
import withRouter from '../components/withRouter';
import { useNavigate } from 'react-router';
import { crudAlertControl } from '../Functions';
import LocationAlert from '../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const PermissionsDetail = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [role, setRole] = useState([]);
    const [modules, setModules] = useState([]);
    const [categories, setCategories] = useState([]);

    const updateData = async (event) => {
        event.preventDefault();

        const modules = await import("../data/modules.js");
        const data = await modules.updateRole(role.id, role.name, event.target.rolename.value);
        var rolemodules = Array.from(document.getElementsByClassName("module")).filter(module => module.checked).map(module => module.dataset.id);

        modules.updateModulesForRole(props.router.params.id, role.name, rolemodules).then(data => {
                crudAlertControl('update', data);
        });
    }

    const switchModule = async () => {
        const modules = await import("../data/modules.js");
        var rolemodules = Array.from(document.getElementsByClassName("module")).filter(module => module.checked).map(module => module.dataset.id);
        modules.updateModulesForRole(props.router.params.id, role.name, role.name, rolemodules);
    }

    const getData = async () => {
        const modules = await import("../data/modules.js");
        const roleData = await modules.getRoles(props.router.params.id);
        const modulesData = await modules.getModules();
        const categoriesData = await modules.getCategories();

        setRole(roleData[0]);
        setModules(modulesData);
        setCategories(categoriesData);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />
            
            <div className="loc_content">
                <form type="POST" onSubmit={(event) => updateData(event)}>
                    <input className="btn btn-save" style={{ marginTop: '0px' }} type="submit" name="submit" value={t('save')} />
                    <h1><div style={{ cursor: 'pointer', display: 'inline-block' }} className="goBackBtn" onClick={() => navigate("/manage/permissions")}><i className="fas fa-arrow-circle-left"></i></div> {role.slug ? t(`${role.slug}`) : role.name}</h1>

                    <div className="alert alert-success alert-update" role="alert">
                        {t('updaterole')}
                    </div>

                    <div className="alert alert-danger" role="alert">
                        {t('somethingwrong')}
                    </div>


                    <div className="orgDetails">
                        <table className="table">
                            <tr className="roweven">
                                <td className="td">{t('name')}</td>
                                <td className="td"><input type="text" name="rolename" id="rolename" defaultValue={role.slug ? t(`${role.slug}`) : role.name} className="form-control" /></td>
                            </tr>
                        </table>
                    </div>
                </form>

                {categories.map((categorie, i) => {
                    return (
                        <div className="modulesTable">
                            <div className="modulesCategory">{categorie.name}</div>
                            <table>
                                <thead>
                                    <tr className="rowtitle">
                                        <th>{t('name')}</th>
                                        <th>{t('active')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modules.map((module, i) => {
                                        var checked = role.modules && role.modules.split(",").includes(module.id.toString()) ? true : false;

                                        return ( module.categorie === categorie.id ? <tr style={{ cursor: 'pointer' }} key={i}>
                                                <td><i style={{ color: '#0085CA', marginRight: '1.125rem' }} className={`icon ${module.icon}`}></i> {t(`${module.slug}`)}</td>
                                                <td>
                                                    <label className='switch' style={{ float: 'right', marginRight: '30px' }}>
                                                        <input key={`${Math.floor((Math.random() * 1000))}-min`} defaultChecked={checked} data-id={module.id} className="module" type='checkbox' />
                                                        <span className='slider round'></span></label>
                                                </td>
                                            </tr> : '' )
                                    })}
                                </tbody>
                            </table>
                        </div>);
                })};
            </div>
        </React.Fragment>
    );
}

export default withRouter(PermissionsDetail);
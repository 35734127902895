import React, { Component, useEffect, useState } from 'react';
import './Manage.css';
import '../components/DataTables.css';
import withRouter from '../components/withRouter';
import * as moment from 'moment';
import { crudAlertControl, initDataTable } from "../Functions";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const WelfareDetails = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [alarm, setAlarm] = useState([]);
    const [icons, setIcons] = useState([]);
    const [sound, setSound] = useState([]);
    const [devices, setDevices] = useState([]);
    const [types, setTypes] = useState([]);

    const changeData = async (alarm, event) => {
        event.preventDefault();
        const welfare = await import("../data/welfare.js");
        const data = await welfare.updateWelfareAlert(alarm.id, event.target.name.value, event.target.type.value, event.target.icon1 ? event.target.icon1.value : 0, event.target.icon2 ? event.target.icon2.value : 0, event.target.icon3 ? event.target.icon3.value : 0, event.target.sound ? event.target.sound.value : 0, alarm.type == 2 ? '0:01' : (event.target.time ? event.target.time.value : 0), event.target.repeat ? event.target.repeat.value : 0,  event.target.interval ? event.target.interval.value : 0);
        crudAlertControl('update', data);
    }

    const switchDevice = async (event, alarmid, alarmname, deviceid, devicename) => {
        const welfare = await import("../data/welfare.js");
        event.target.checked ? welfare.insertDeviceIntoAlert(alarmid, alarmname, deviceid, devicename) : welfare.deleteDeviceOfAlert(alarmid, alarmname, deviceid, devicename);
    }

    const getData = async () => {
        const welfare = await import("../data/welfare.js");
        const data = await welfare.getWelfareAlarmById(props.router.params.id);
        const devices = await welfare.getWelfareDevices(props.router.params.id, data[0].type == 2 ? 10 : 7);
        const welfaretypes = await welfare.getWelfareTypes();
        setTypes(welfaretypes);
        setDevices(devices);
        setAlarm(data[0]);
        initDataTable();
    } 

    useEffect(() => {
        getData();
    }, [])

    const timearray = [];

    for (let i = 0; i <= 23; i++) {
        for (let j = 0; j < 60; j += 15) {
            timearray.push(`${i.toString().padStart(2, '0')}:${j.toString().padStart(2, '0')}`);
        }
    }

    return (
        <React.Fragment>
            <div className='loc_content'>
                <form type="POST" onSubmit={(event) => changeData(alarm, event)}>
                    <input className="btn btn-save" style={{ marginTop: '0' }} type="submit" name="submit" value={t('save')} />
                    <h1><button className="goBackBtn" onClick={() => navigate('/manage/welfaresettings')}><i class="fas fa-arrow-circle-left"></i></button> {t('editalert')}</h1>

                    <div className="alert alert-success alert-update" role="alert">
                        {t('updatealert')}
                    </div>

                    <div class="alert alert-danger" role="alert">
                        {t('somethingwrong')}
                    </div>

                    <div class="orgDetails">
                        <div class="row">
                            <div class="col-md-12">
                                {t('name')}: <input type="text" className="form-control" id="name" name="name" defaultValue={alarm.name} />
                            </div>
                        </div>

                    <div class="row" style={{ marginTop: '20px' }}>
                        <div class="col-md-12">
                            {t('type')}: <select name="type" id="type" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={alarm.type} class="form-control welfare-type" data-id={alarm.type}>
                                {types.map((type, i) => {
                                    return (<option value={type.id}>{type.type}</option>)
                                })}
                            </select>
                        </div>
                    </div>
                    
                    {alarm.type != 2 && (<div class="row" style={{ marginTop: '20px' }}>
                        <div class="col-md-12">
                            {t('time')}: <select name="time" id="time" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={alarm.datealert} class="form-control welfare-time" data-id={alarm.id}>
                                <option value="0">{t('select')}</option>
                                {timearray.map((time, i) => {
                                    return (<option value={time}>{time}</option>)
                                })}
                            </select>
                        </div>
                    </div>)}

                    {alarm.type != 2 && ( <div class="row" style={{ marginTop: '20px' }}>
                        <span style={{ width: '100%' }} >{t('icons')}:</span>
                        <div class="col-md-4 icons">
                            <select id="icon1" name="icon1" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={alarm.icon1} class="form-control" data-id='1' data-alarm={alarm.id}>
                                <option value="0">{t('select')}</option>
                                <option value="happy">Happy</option>
                                <option value="less">Less</option>
                                <option value="sad">Sad</option>
                            </select>
                        </div>
                        <div class="col-md-4 icons">
                            <select id="icon2" name="icon2" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={alarm.icon2} class="form-control" data-id='1' data-alarm={alarm.id}>
                                <option value="0">{t('select')}</option>
                                <option value="happy">Happy</option>
                                <option value="less">Less</option>
                                <option value="sad">Sad</option>
                            </select>
                        </div>
                        <div class="col-md-4 icons">
                            <select id="icon3" name="icon3" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={alarm.icon3} class="form-control" data-id='1' data-alarm={alarm.id}>
                                <option value="0">{t('select')}</option>
                                <option value="happy">Happy</option>
                                <option value="less">Less</option>
                                <option value="sad">Sad</option>
                            </select>
                        </div>
                    </div> )}

                    {alarm.type != 2 && (<div class="row" style={{ marginTop: '20px' }}>
                        <div class="col-md-12">
                            <span style={{ width: '100%', display: 'inline-block' }} >{t('sounds')}:</span>
                            <select id="sound" name="sound" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={alarm.sound} class="form-control welfare-sound" data-alarm={alarm.id}>
                                <option value="0">{t('select')}</option>
                                <option value="sound1">Sound1</option>
                                <option value="sound2">Sound2</option>
                                <option value="sound3">Sound3</option>
                            </select>
                        </div>
                    </div> )}
                    
                    {alarm.type != 2 && (<div class="row" style={{ marginTop: '20px' }}>
                        <div class="col-md-6">
                            {t('repeat')}: <input type="number" className="form-control" name="repeat" id="repeat" defaultValue={alarm.repeat} />
                        </div>
                        <div class="col-md-6">
                            {t('interval')}: <input type="number" className="form-control" name="interval" id="interval" defaultValue={alarm.interval} />
                        </div>
                    </div> )}
                </div>
            </form>

                <div className="DataTable_Container">
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr>
                                <th>{t('device')}</th>
                                <th>{t('room')}</th>
                                <th>{t('activated')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {devices.map((device, i) => {
                                var selected = device.enabled === 1 ? true : false;

                                return (
                                    <tr>
                                        <td>{device.name}</td>
                                        <td>{device.object}</td>
                                        <td><label class='switch'>
                                        <div style={{ display: "none" }}>{device.enabled}</div>
                                        <input id={`device_${device.id}`} data-alarm={alarm.id} onClick={(event) => { switchDevice(event, alarm.id, alarm.name, device.id, device.name) }} defaultChecked={selected} class='devicetogBtn' type='checkbox' /><span class='slider round'></span></label>
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
    </React.Fragment>
  );
}

export default withRouter(WelfareDetails);

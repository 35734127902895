import React, { Component, useState, useEffect } from 'react';
import withRouter from '../../components/withRouter';
import { getHeaderTeams } from '../../data/teams';
import { subscribeTeam, unsubscribeTeam } from '../../data/teams';
import '../Header.css';
import { useTranslation } from 'react-i18next';

const TeamsSidebar = () => {
    const { t } = useTranslation();
    const [teams, setTeams] = useState([]);
    const [countTeams, setCountTeams] = useState(0);

    const teamsSwitch = async (event, teamID, teamname) => {
        const teamsData = await import("../../data/teams.js");
        var btn = event.target;
        teamID = teamID.split('_')[1];
        btn.checked ? teamsData.subscribeTeam(teamID, teamname) : teamsData.unsubscribeTeam(teamID, teamname);
    }

    const getData = async () => {
        const teamsData = await import("../../data/teams.js");
        const data = await teamsData.getHeaderTeams();

        var teamcounter = 0;

        data.forEach(team => {
            if(team.invisible === 0){
                teamcounter++;    
            }
        })

        setCountTeams(localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') !== '' && localStorage.getItem('currentLocation') != 0 ? teamcounter : 0);
        setTeams(localStorage.getItem('currentLocation') && localStorage.getItem('currentLocation') !== '' && localStorage.getItem('currentLocation') != 0 ? data : []);
    }

    useEffect(() => {
        getData();

        document.querySelectorAll('.teamsSignUp').forEach(function (elem) {
            elem.addEventListener('click', function () {
                document.querySelector(".teamsList").classList.add('slide-in');
            });
        });
        
        document.querySelectorAll('.closeTeams').forEach(function (elem) {
            elem.addEventListener('click', function () {
                document.querySelector(".teamsList").classList.remove('slide-in');
            });
        });
    }, [])

    return (
        <React.Fragment>
            <span className="header-icon teamsSignUp">
                <i className="fas fa-users"></i>
                <span className="header-badge badge rounded-pill bg-info">{countTeams}</span>
            </span>
            <div className="teamsList">
                <div className="closeTeams">
                    <i className="fas fa-times"></i>
                </div>

                <h6 className="teamsList_title">
                    <i className="fas fa-users"></i> {t('teams')}
                </h6>

                {!localStorage.getItem("currentLocation") || localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ?
                    <div style={{ display: localStorage.getItem("currentLocation") && localStorage.getItem("currentLocation") !== '' ? "none" : "block" }} className="alert alert-warning msg_no_location" role="alert">
                        {t('location_not_selected')}
                    </div> : ''}

                <table>
                    <tbody>
                        {teams.map((team, i) => {
                            var checked = team.current === "ON" ? true : false;

                            return (team.invisible === 0 && team.defaulton === 1 ?
                                <tr key={i}>
                                    <td style={{ color: '#bbb' }}>{team.name}</td>
                                    <td>
                                        <label className='switch'>
                                            <input id={`switch-${team.id}`} className='togBtn' type='checkbox' defaultChecked={checked} disabled />
                                            <span className='slider round disabled_switch'></span></label>
                                    </td>
                                </tr> : (team.invisible === 0 ?
                                    <tr key={i}>
                                        <td style={{ color: '#333' }}>{team.name}</td>
                                        <td>
                                            <label className='switch'>
                                                <input id={`switch-${team.id}`} onClick={(event) => teamsSwitch(event, team.id, team.name)} className='togBtn' type='checkbox' defaultChecked={checked} />
                                                <span className='slider round disabled_switch'></span></label>
                                        </td>
                                    </tr> : ''));
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
}

export default withRouter(TeamsSidebar);
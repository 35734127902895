import React, { Component, useState, useEffect } from 'react';
import withAuth from '../components/Login/withAuth';
import './Tracking.css';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import * as moment from 'moment';
import { t } from 'i18next';
moment.locale('nl');
registerLocale('nl', nl);

const Tracking = () => {
    const [devices, setDevices] = useState([]);

    const getData = async () => {
        const [blyott, functions] = await Promise.all([
            import("../data/blyott.js"),
            import("../Functions.js")
        ]);

        const deviceData = await blyott.getDevices();
        setDevices(deviceData);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <h1>{t('tracking')}</h1>
                <div className="DataTable_Container">
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th>{t('mac')}</th>
                                <th>{t('devicename')}</th>
                                <th>{t('currentlocation')}</th>
                                <th>{t('lastsignal')}</th>
                            </tr>
                        </thead>
                        <tbody>

                            {devices.map((device, i) => {
                                return (
                                    <tr onClick={() => { window.location.href = `/manage/tracking/${device.tagmac}` }}>
                                        <td>{device.tagmac}</td>
                                        <td>{device.devicename}</td>
                                        <td>{device.location ? device.location : "Unknown"}</td>
                                        <td>{moment(device.time).format("DD-MM-YYYY HH:mm:ss")}</td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withAuth(Tracking);
import React, { Component, useEffect, useState } from 'react';
import './Manage.css';
import '../components/DataTables.css';
import withRouter from '../components/withRouter';
import { useNavigate } from 'react-router';
import { confirmDelete, crudAlertControl } from '../Functions';
import { useTranslation } from 'react-i18next';
import ModalDelete from '../components/ModalDelete';

const TeamRouteDetails = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [route, setRoute] = useState([]);
    const [teams, setTeams] = useState([]);
    const [steps, setSteps] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [stepcount, setStepcount] = useState(1);

    const changeData = async (event) => {
        event.preventDefault();
        const routesData = await import("../data/routes");
        const data = await routesData.updateRoute(props.router.params.id, event.target.name.value);
        getData();
        crudAlertControl('update', data);
    }

    const addStep = async (event) => {
        event.preventDefault();
        const routesData = await import("../data/routes");
        const data = await routesData.insertStepInRoute(props.router.params.id, route.routename, event.target.team.value, event.target.sequence.value, event.target.timeout.value);
        getData();
        crudAlertControl('add', data);
        modalHide();
    }

    const updateStep = async (stepid, teamid, timeout) => {
        const routesData = await import("../data/routes");
        const data = await routesData.updateStep(stepid, props.router.params.id, route.routename, teamid, timeout);
        getData();
        crudAlertControl('update', data);
    }

    const deleteStep = async (row, stepid) => {
        const routesData = await import("../data/routes");
        const data = await routesData.deleteStep(stepid, route.routename);
        getData();
        crudAlertControl('delete', data);
    }

    const getData = async () => {
        const [routesData, teamsData] = await Promise.all([
            import("../data/routes"),
            import("../data/teams")
        ])

        const getroutes = await routesData.getRoutes(props.router.params.id);
        const getteams = await teamsData.getTeams();
        const getsteps = await routesData.getStep(props.router.params.id);
        var stepCounter = 1;

        getsteps.forEach(function () {
            stepCounter++
        })

        setStepcount(stepCounter);

        setRoute(getroutes[0]);
        setTeams(getteams);
        setSteps(getsteps);
    }

    const modalShow = () => {
        setShowModal(true);
    }

    const modalHide = () => {
        setShowModal(false);
    }

    useEffect(() => {
        getData(true);
    }, [])

  return (
    <React.Fragment>
      <div className='loc_content'>
        <form type="POST" onSubmit={(event) => changeData(event)}>
          <input style={{ marginTop: '0px' }} class="btn btn-save" type="submit" name="submit" value={t('save')} />
          <h1><button className="goBackBtn" onClick={() => navigate('/manage/teamroutes')}><i class="fas fa-arrow-circle-left"></i></button> {t('configuration')}</h1>

          <div className="alert alert-success alert-update" role="alert">
            {t('updateroute')}
          </div>

          <div className="alert alert-success alert-add" role="alert">
            {t('newstep')}
          </div>

          <div className="alert alert-success alert-delete" role="alert">
            {t('delstep')}
          </div>

          <div class="alert alert-danger" role="alert">
              {t('somethingwrong')}
          </div>

          <div class="orgDetails">
            <table class="table">
              <tr class="rowodd">
                <td class="td">{t('id')}</td>
                <td class="td">{route.routeid}</td>
              </tr>
              <tr class="roweven">
                <td class="td">{t('name')}</td>
                <td class="td"><input type="text" name="name" id="name" defaultValue={route.routename} class="form-control" /></td>
              </tr>
            </table>
          </div>
        </form>

        <div onClick={() => modalShow()} class="btn btn-add navbutton">{t('addstep')}</div>
        <h1>Stappen</h1>
        <div class="orgDetails">
          <table class="table">
            <thead>
              <tr>
                <th width="5%">{t('step')}</th>
                <th width="31%">{t('team')}</th>
                <th width="50%">{t('timeout')}</th>
                <th width="12%"></th>
                <th width="2%"></th>
              </tr>
            </thead>
            <tbody>

              {steps.map((step, i) => {
                return (
                  <tr>
                    <td>
                      {step.sequence}
                    </td>
                    <td>
                      <input type="hidden" id="routeTeamID" defaultValue={step.sequencetimeout} />
                      <select name={`teamname${step.teamid}`} onChange={(event) => updateStep(step.stepid, event.target.value, step.sequencetimeout)} id={`teamname${step.teamid}`} defaultValue={step.teamid} class="form-control">
                        {teams.map((team, i) => {
                          return (<option value={team.teamid} selected={step.teamid === team.teamid ? true : false}>{team.name}</option>)
                        })}
                      </select>
                    </td>
                    <td class="td"><input type="number" style={{ width: '100px', display: 'inline-block', marginRight: '10px' }} className="form-control" defaultValue={step.sequencetimeout} name={`timeout${step.teamid}`} onInput={(event) => updateStep(step.stepid, step.teamid, event.target.value)} /> {t('seconds')}</td>
                    <td class="td"></td>
                    <td><i style={{ cursor: 'pointer' }} onClick={(event) => confirmDelete(t('delitem'), function () { deleteStep(event, step.stepid) })} class="btn-delete fas fa-trash-alt"></i></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <ModalDelete />

      {showModal && ( <div style={{ display: 'block' }} id="add" class="modal fade in">
        <div class="modal-content ">
          <div class="modal-header">
            <h4 class="modal-title">{t('addstep')}</h4>
            <div onClick={() => modalHide()} style={{ cursor: 'pointer' }} class="close">&times;</div>
          </div>
          <form type="POST" onSubmit={(event) => addStep(event)}>
            <div class="modal-body">
              <div>
                <div class="form-group">
                  <label for="team">{t('team')}</label>
                  <input type="hidden" name="sequence" id="sequence" defaultValue={stepcount} />
                  <select name="team" id="team" class="form-control">
                    {teams.map((team, i) => {
                      return (<option value={team.teamid}>{team.name}</option>)
                    })}
                  </select>
                </div>
                <div class="form-group">
                  <label for="location">{t('timeout')}</label>
                  <input type="number" style={{ width: '100px', display: 'inline-block', marginLeft: '10px', marginRight: '10px' }} id="timeout" name="timeout" className="form-control" /> {t('seconds')}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row">
                <div class="col-md-6">
                  <div onClick={() => modalHide()} id="cancel" class="btn btn-red cancel">{t('cancel')}</div>
                </div>
                <div class="col-md-6">
                  <button type="submit" className="btn btn-green">{t('add')}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> )}
    </React.Fragment>
  );
}

export default withRouter(TeamRouteDetails);

import React, { Component, useEffect, useState } from 'react';
import './Reports.css';
import '../../components/DataTables.css';
import withRouter from '../../components/withRouter';
import * as moment from 'moment';
import { initDataTable, filterDataTable, fillDataTableFilters } from "../../Functions.js";
import LocationAlert from "../../components/LocationAlert.js";
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Export from '../../components/Export.js';
registerLocale('nl', nl);
moment.locale('nl');

const ReportWelfare = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [reports, setReports] = useState([]);
    const [alarmtypes, setAlarmtypes] = useState([]);
    const [alarmrooms, setAlarmrooms] = useState([]);
    const [displays, setDisplays] = useState([]);
    var currentdate = new Date(props.router.params.reportdate);

    const getWelfareReportsOfDate = async (date) => {
        const welfare = await import("../../data/welfare.js");
        const data = await welfare.getWelfareReports(date);
        setReports(data);
        initDataTable();
    }

    const goToDate = (date) => {
        navigate('/reports/welfare/' + moment(date).format('YYYY-MM-DD'));
        getWelfareReportsOfDate(moment(date).format('YYYY-MM-DD'));
    }

    useEffect(() => {
        getWelfareReportsOfDate(props.router.params.reportdate);
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{t('welfarereports')}</h1>

                <div class="filters">
                    {t('date')}: <DatePicker locale="nl" dateFormat="yyyy-MM-dd"
                    selected={currentdate}
                    onChange={(data, e) => goToDate(data)}
                />

                {t('alert')}:
                <select id="typeAlarm" onChange={(event) => filterDataTable("reportwelfarealarm-alarmtype", 3, event.target.value, false)}>
                    <option data-id="0" value="">{t('select')}</option>
                </select>

                {t('device')}:
                <select id="display" onChange={(event) => filterDataTable("reportwelfarealarm-display", 1, event.target.value, false)}>
                    <option data-id="0" value="">{t('select')}</option>
                </select>

                {t('location')}:
                <select id="roomAlarm" onChange={(event) => filterDataTable("reportwelfarealarm-room", 2, event.target.value, false)}>
                    <option data-id="0" value="">{t('select')}</option>
                </select>

                <Export />
            </div>

                <div className="DataTable_Container">
                    <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr class="rowtitle">
                                <th>{t('id')}</th>
                                <th>{t('device')}</th>
                                <th>{t('location')}</th>
                                <th>{t('alert')}</th>
                                <th>{t('reaction')}</th>
                                <th>{t('alertdate')}</th>
                                <th>{t('reactiondate')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports.map((report, i) => {
                                fillDataTableFilters(document.getElementById('typeAlarm'), report.welfarename);
                                fillDataTableFilters(document.getElementById('display'), report.displayname);
                                fillDataTableFilters(document.getElementById('roomAlarm'), report.object);

                                return (
                                    <tr>
                                        <td>{report.id}</td>
                                        <td>{report.displayname}</td>
                                        <td>{report.object}</td>
                                        <td>{report.welfarename}</td>
                                        <td>{report.reaction}</td>
                                        <td>{report.datecreated && report.datecreated !== "" && report.datecreated !== null ? moment(report.datecreated).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                        <td>{report.datereaction && report.datereaction !== "" && report.datereaction !== null ? moment(report.datereaction).format('DD-MM-YYYY HH:mm:ss') : ''}</td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(ReportWelfare);
import React, { Component, useEffect, useState } from 'react';
import './Manage.css';
import '../components/DataTables.css';
import withRouter from '../components/withRouter';
import * as moment from 'moment';
import { crudAlertControl, initDataTable } from "../Functions.js";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const TipsDetails = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tip, setTip] = useState([]);
    const [modules, setModules] = useState([]);

    const changeData = async (event) => {
        // event.preventDefault();

        // const [teamsData, objectsData] = await Promise.all([
        //     import("../data/teams.js"),
        //     import("../data/objects.js")
        // ])

        // const data = await teamsData.updateTeam(props.router.params.id, event.target.name.value, event.target.related.value, event.target.pbx.value);
        // await getData();

        // crudAlertControl('update', data);
    }

    const getData = async () => {
        const [tipsData, modulesData] = await Promise.all([
            import("../data/tips"),
            import("../data/modules")
        ]);

        const getAllTips = await tipsData.getTip(props.router.params.id);
        const getTipsModules = await modulesData.getModules();

        console.log(getAllTips);

        setTip(getAllTips[0]);
        setModules(getTipsModules);
        initDataTable();
    }

    useEffect(() => {
        getData(true);
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <form type="POST" onSubmit={(event) => changeData(event)}>
                    <input className="btn btn-save" style={{ marginTop: '0' }} type="submit" name="submit" value={t('save')} />
                    <h1><button className="goBackBtn" onClick={() => navigate('/manage/tips')}><i class="fas fa-arrow-circle-left"></i></button> {t('tips')} {t('configuration')}</h1>

                    <div class="alert alert-danger" role="alert">
                        {t('somethingwrong')}
                    </div>

                    <div class="orgDetails">

                        <table class="table">
                            <tr class="roweven">
                                <td class="td">{t('front')}</td>
                                <td class="td"><input key={`${Math.floor((Math.random() * 1000))}-min`} type="text" name="name" id="name" defaultValue={tip.fronttext} className="form-control" /></td>
                            </tr>
                            <tr class="roweven">
                                <td class="td">{t('module')}</td>
                                <td class="td">
                                <select key={`${Math.floor((Math.random() * 1000))}-min`} name="moduleid" id="moduleid" defaultValue={tip.module} className="form-control">
                                    <option value="0">{t('select')}</option>

                                    {modules.map((module) => {
                                        return (<option value={module.id}>{t(`${module.slug}`)}</option>);
                                    })}
                                </select>
                                </td>
                            </tr>
                            <tr class="roweven">
                                <td class="td">{t('back')}</td>
                                <td class="td"><input key={`${Math.floor((Math.random() * 1000))}-min`} type="text" name="name" id="name" defaultValue={tip.backtext} className="form-control" /></td>
                            </tr>
                            
                        </table>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}

export default withRouter(TipsDetails);

import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import withAuth from '../../components/Login/withAuth';
import LocationAlert from "../../components/LocationAlert.js";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const Employees = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [teams, setTeams] = useState([]);

    const getData = async () => {
        const [teamsData, functions] = await Promise.all([
            import("../../data/teams.js"),
            import("../../Functions.js")
        ])

        const data = await teamsData.getTeams();
        setTeams(data);
        functions.initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{t('employeesperteam')}</h1>

                <div class="DataTable_Container">
                    <table id="DataTable" class="table responsive nowrap">
                        <thead>
                            <tr class="rowtitle">
                                <th>{t('team')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teams.map((team, i) => {
                                return (
                                    <tr onClick={() => { navigate(`/employees/team/${team.teamid}`) }}>
                                        <td style={{ paddingLeft: "15px" }}>{team.name}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withAuth(Employees);

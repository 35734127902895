import React, { useEffect, useState } from 'react';
import './Login.css';
import AuthService from './AuthService';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { basicReduxStore } from './ADAuth/reduxStore';
import { authProvider } from './ADAuth/authProvider';
import { AuthVerification, QRLogin, APIStatus } from '../../data/login';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { MsalProvider, useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { SignInButton } from './ADAuth/signInButton';
import { msalConfig } from "./ADAuth/authConfig.js";
import { PublicClientApplication } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from 'msal';

const $ = require('jquery');

const Login = (props) => {
    const msalInstance = new PublicClientApplication(msalConfig);
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [verify, setVerify] = useState(0);
    const [status, setStatus] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loggedin, setLoggedin] = useState('');
    
    var Auth = new AuthService();

    const getGraphInfo = (accessToken, idToken) => {
        return fetch("https://graph.microsoft.com/v1.0/me", {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + accessToken,
              'Content-Type': 'application/json',
            },
          })
          .then(response => response.json())
          .then(data => {
            console.log(data.givenName + " " + data.surname, idToken);
                Auth.ADLogin(data.givenName + " " + data.surname, idToken);
          });
    }

    const QRCode = () => {
        QRLogin().then(data => {
            if (data[0] && data[0].value === 1) {
                $('.login').hide();
                $('.verification').show();
            } else {
                if(loggedin){
                    navigate("/");    
                }
            }
        })

        return null;
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        Auth.login(username, password).then((res) => {
            if (res && res.user.id) {
                setLoggedin(true);
                window.location.reload();
            } else {
                if (res !== false && res.user.id) {
                    QRCode();
                } else {
                    $('.err_msg').html(t('invalidcredentials'));
                    $('.err_msg').fadeIn();
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };


    const handleChange = (e) => {
        if(e.target.name === 'username'){
            setUsername(e.target.value);
        }else{
            setPassword(e.target.value);
        }
    }

    const text = verify;

    useEffect(() => {

        // if (Auth.isAD() && Auth.isAD() === "true") {
        //     if (Auth.loggedIn()) {
        //         navigate('/');
        //     } else {
        //         Auth.logout();
        //         navigate('/login');
        //     }
        // } else {
        //     if (Auth.loggedIn()) {
        //         if (Auth.isVerified()) {
        //             if(loggedin){
        //                 navigate('/');    
        //             }
        //         } else {
        //             Auth.logout();
        //             // navigate('/login');
        //         }
        //     }
        // }

        // AuthVerification().then(code => {
        //     setVerify(code);
        // })

        APIStatus().then(data => {
            setStatus(data);
        })

        // $('.verify').on('click', function () {
        //     if ($('#verifyCode').val() === text) {
        //         localStorage.setItem('verified', true)
        //         window.location.href = "/"
        //     }
        //     else {
        //         $('.msg').html(t('invalid_code'));
        //         $('.msg').fadeIn()
        //     }
        // });

        const handleEvent = (event) => {
            const { accessToken, idToken } = event.payload;

            if (accessToken && idToken) {
                sessionStorage.setItem('AccessToken', accessToken);
                getGraphInfo(accessToken);
            }
        };

        msalInstance.addEventCallback(handleEvent);
    }, [])

    return (
        <div className="center">
            <img className="login_logo" alt="logo" src="/images/sltn-hps-logo.png" />
            <div className="login">
                <form onSubmit={(e) => handleFormSubmit(e)}>
                    <input
                        className="form-control"
                        placeholder={t('username')}
                        name="username"
                        type="text"
                        onChange={handleChange}
                    />
                    <input
                        className="form-control"
                        placeholder={t('password')}
                        name="password"
                        type="password"
                        onChange={handleChange}
                    />
                    <input
                        className="form-submit"
                        value={t('login')}
                        type="submit"
                    />
                    <div className="err_msg"></div>
                </form>

                <MsalProvider instance={msalInstance}>
                    <SignInButton />
                </MsalProvider>

                {/* <AzureAD provider={authProvider} reduxStore={basicReduxStore}>
                    {({ login, logout, authenticationState, error, accountInfo }) => {
                        console.log(authProvider);
                        const isAuthenticated = authenticationState === AuthenticationState.Authenticated;

                        const fetchGraphData = async () => {
                            try {
                                const tokenResponse = await authProvider.getAccessToken();
                                const accessToken = tokenResponse.accessToken;
                
                                const graphResponse = await fetch('https://graph.microsoft.com/v1.0/me', {
                                    headers: {
                                        Authorization: `Bearer ${accessToken}`,
                                    },
                                });
                
                                const userData = await graphResponse.json();
                                console.log('User data from Graph API:', userData);
                            } catch (error) {
                                console.error('Error fetching data from Graph API:', error);
                            }
                        };

                        if (isAuthenticated) {
                            console.log('authenticated');
                            console.log(accountInfo);
                            fetchGraphData();
                        }

                        return (
                            <button className="btn-azure" onClick={login} >
                                <i className="fab fa-windows"></i> Azure login
                            </button>
                        );
                    }}
                </AzureAD> */}
                <div className='api-status' style={{ background: status === "OK" ? 'green' : '#FF0000' }}></div>
            </div>

            <div className="verification">
                <QRCode text={`Toegangscode CHC: ${text}`} size="150" />
                <h2 style={{ textAlign: 'center', marginTop: '20px' }} >{t('qrcode')}</h2>
                <p>{t('qrcodetext')}</p>

                <div style={{ marginTop: '20px', fontSize: '14px' }}>
                    Code <input style={{ border: 'solid 1px #dddddd', height: '30px', padding: '5px' }} type="text" name="verifyCode" id="verifyCode" />
                    <button className="verify">{t('confirm')}</button>
                    <div className="msg"></div>
                </div>
            </div>
        </div>
    ); 
}

export default Login;
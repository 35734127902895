import React, { Component, useEffect, useState } from 'react';
import './Manage.css';
import '../components/DataTables.css';
import withAuth from '../components/Login/withAuth.js';
import * as moment from 'moment';
import { initDataTable, bulkSelect, confirmDelete, crudAlertControl } from "../Functions.js";
import { useNavigate } from 'react-router';
import ModalDelete from "../components/ModalDelete";
import { useTranslation } from 'react-i18next';
moment.locale('nl');

const WelfareSettings = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [settings, setSettings] = useState([]);
    const [types, setTypes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const bulk = [];

    const addAlert = async (event) => {
        event.preventDefault();

        const welfare = await import("../data/welfare.js");
        const data = await welfare.addWelfareAlert(event.target.name.value, event.target.type.value, event.target.type.value == 2 ? "0:01" : null);

        if(data && data.length > 0){
          navigate('/manage/welfaresettings/' + data[0].id);
        }
    }

    const alarmSwitch = async (event, AlarmID, alarmname) => {
        const welfare = await import("../data/welfare.js");
        event.target.checked ? welfare.activateWelfareAlert(AlarmID, alarmname) : welfare.deactivateWelfareAlert(AlarmID, alarmname); 
    }

    const deleteAlert = async (row, objectID, alarmname) => {
        const welfare = await import("../data/welfare.js");
        const data = await welfare.deleteWelfareAlert(objectID, alarmname);
        getData();
        crudAlertControl('delete', data);
    }

    const bulkDeleteAlert = async (event, array) => {
        const welfare = await import("../data/welfare.js");

        array.forEach(async (alarm) => {
            const data = await welfare.deleteWelfareAlert(alarm);
            getData();
        })
    }

    const modalShow = () => {
        setShowModal(true);
    }

    const modalHide = () => {
        setShowModal(false);
    }

    const getData = async (init) => {
        const welfare = await import("../data/welfare.js");
        const welfareData = await welfare.getWelfareAlarms();
        const welfareTypes = await welfare.getWelfareTypes();

        setSettings(welfareData);
        setTypes(welfareTypes);
        initDataTable();
    }

    useEffect(() => {
        getData();
    }, [])

  return (
    <React.Fragment>
      <div className='loc_content'>
        <div onClick={() => modalShow()} class="btn btn-add">{t('add')}</div>
        <h1>{t('welfaremanagement')}</h1>

        <div className="alert alert-success alert-add" role="alert">
            {t('newalert')}
        </div>

        <div className="alert alert-success alert-delete" role="alert">
            {t('delalert')}
        </div>

        <div class="alert alert-danger" role="alert">
            {t('somethingwrong')}
        </div>

        <div className="DataTable_Container">
          <div class="bulk" style={{ marginTop: '20px' }}>
            <span style={{ color: '#000000', marginRight: '10px' }}>{t('bulkselect')}: </span>
            <i onClick={(event) => confirmDelete(function () { bulkDeleteAlert(event, bulk) })} className="btn-delete fas fa-trash-alt"></i>
          </div>
          <table id="DataTable" class="showHead table responsive nowrap" width="100%">
            <thead>
              <tr class="rowtitle">
                <th></th>
                <th>{t('alert')}</th>
                <th>{t('type')}</th>
                <th>{t('createdon')}</th>
                <th>{t('timealert')}</th>
                <th>{t('active')}</th>
                <th>{t('delete')}</th>
              </tr>
            </thead>
            <tbody>
              {settings.map((alarm, i) => {
                var checked = alarm.active === 1 ? true : false;

                return (
                  <tr key={i}>
                    <td style={{ width: '1px' }} data-id={alarm.id}><input type="checkbox" className="bulkSelect" onChange={(event) => bulkSelect(event, bulk)} /></td>
                    <td onClick={(event) => { navigate(`/manage/welfaresettings/${alarm.id}`) }}>{alarm.name}</td>
                    <td>{alarm.type}</td>
                    <td>{moment(alarm.datecreated).format('DD-MM-YYYY HH:mm')}</td>
                    <td>{alarm.datealert}</td>
                    <td>
                      <label class='switch'>
                        <input id={`Welfarealarm_${alarm.id}`} onClick={(event) => { alarmSwitch(event, alarm.id, alarm.name) }} class='WelfareAlarmtogBtn' type='checkbox' defaultChecked={checked} />
                        <span class='slider round'></span>
                      </label>
                    </td>
                    <td><i onClick={(event) => confirmDelete(t('delitem'), function () { deleteAlert(event, alarm.id, alarm.name) })} class="btn-delete fas fa-trash-alt"></i></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <ModalDelete />

      {showModal && ( <div style={{ display: 'block' }} id="add" class="modal modal-add fade in">
        <div class="modal-content ">
          <div class="modal-header">
            <h4 class="modal-title">{t('addalert')}</h4>
            <div onClick={() => modalHide()} class="close">&times;</div>
          </div>
          <form onSubmit={(event) => addAlert(event)}>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div class="form-group">
                    <label for="teamname">{t('name')}</label>
                    <input type="text" name="name" id="name" class="form-control" required />
                  </div>
                  <div class="form-group">
                    <label for="type">{t('type')}</label>
                    <select name="type" id="type" className="form-control" required>
                    <option value="0">{t('select')}</option>
                      {types.map(type => {
                        return(
                          <option value={type.id}>{type.type}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div onClick={() => modalHide()} id="cancel" class="btn btn-red cancel">{t('cancel')}</div>
                </div>
                <div class="col-md-6">
                  <button type="submit" className="btn btn-green">{t('add')}</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div> )}
    </React.Fragment>
  );
}

export default withAuth(WelfareSettings);

import React, { Component, useEffect, useState } from 'react';
import './Manage.css';
import '../components/DataTables.css';
import withRouter from '../components/withRouter';
import { updateOrgConffield } from '../data/organisation';
import { confirmDelete, crudAlertControl } from '../Functions';
import { useNavigate } from 'react-router';
import ModalDelete from '../components/ModalDelete';
import { useTranslation } from 'react-i18next';
import ObjectLocation from '../components/ObjectLocation.js';
const $ = require('jquery');

const OrganisationDetails = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [object, setObject] = useState([]);
    const [item, setItem] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [teams, setTeams] = useState([]);
    const [locations, setLocations] = useState([]);
    const [nightnurseLocations, setNightnurseLocations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [devices, setDevices] = useState([]);
    const [detailconf, setDetailconf] = useState([]);
    const [webrole, setWebrole] = useState([]);
    const [customDestination, setCustomdestination] = useState([]);
    const [customDestination1, setCustomdestination1] = useState([]);
    const [customDestination2, setCustomdestination2] = useState([]);
    const [outgoingmsg, setOutgoingmsg] = useState([]);
    const [timetables, setTimetables] = useState([]);
    const [messages, setMessages] = useState([]);
    const objectsList = ['location', 'building', 'floor', 'room', 'employee', 'client', 'sharedspace'];
    const currentObject = objectsList[(props.router.params.objectid - 1)]

    const changeData = async (event) => {
        event.preventDefault();
        const organization = await import("../data/organisation.js");
        const data = await organization.updateObject(props.router.params.id, event.target.name.value, event.target.related.value, event.target.route.value);
        crudAlertControl('update', data);
    }

    const delResponseRule = async(ruleid) => {
      const responserules = await import("../data/responserules.js");
      const data = await responserules.deleteResponseRule(ruleid);
      crudAlertControl('delete', data);
  }

    const changeRules = async (event, name) => {
        event.preventDefault();

        console.log(event);

        const [organisation, responserules] = await Promise.all([
            await import("../data/organisation.js"),
            await import("../data/responserules.js")
        ]) 
        
        const messages = await organisation.getMessage(props.router.params.id);

        await Promise.all(messages.map(async (message) => {
          const msgid = message.messageid;
          
            const data = await responserules.getRuleByMsg(message.id, props.router.params.id);
            
            
            await Promise.all(data.map(async (row) => {

              var rowEnabled = "0";
              var rowActive = "0";

              if ($(`#enabled_${row.incomingmessage}`).is(":checked")) {
                rowEnabled = "1";
              } else {
                rowEnabled = "0";
              }

              if ($(`#active_${row.incomingmessage}`).is(":checked")) {
                rowActive = "1";
              } else {
                rowActive = "0";
              }
              
              if(row.nrofrows > 0){
                if ($(`#outgoingMsg_${row.incomingmessage}`).val() > 1) {
                  const responseData = await responserules.updateResponseRule(msgid, $(`#outgoingMsg_${row.incomingmessage}`).val(), $(`#outgoingRoute_${row.incomingmessage}`).val(), $(`#timetable_${row.incomingmessage}`).val(), rowEnabled, rowActive);
                  crudAlertControl('update', responseData);
                }
              }else{
                if ($(`#outgoingMsg_${row.incomingmessage}`).val() > 1) {
                  const responseData = await responserules.insertResponseRule(msgid, props.router.params.id, $(`#outgoingMsg_${row.incomingmessage}`).val(), $(`#outgoingRoute_${row.incomingmessage}`).val(), $(`#timetable_${row.incomingmessage}`).val(), rowEnabled, rowActive);
                  crudAlertControl('update', responseData);
                }
                
              }
                
            }));
        }));
    }

    const resetDashboard = async (employeeid, name) => {
        const dashboard = await import("../data/dashboard.js");
        const message = await dashboard.resetDashboard(employeeid, name);
        $('.notice-dashboard-reset').show();
    }

    const setUserWebrole = async (employeeid, name, paramtype, value) => {
      const organisation = await import("../data/organisation.js");

      if(!webrole || webrole && webrole.length === 0){
        await organisation.addObjectParam(employeeid, name, paramtype, value)
      }else{
        await organisation.updateOrgConffield(employeeid, name, paramtype, value);
      }

    }

    const getData = async () => {
        const [objects, organisation, teamsData, routesData, modules, nightnurse] = await Promise.all([
            import("../data/objects.js"),
            import("../data/organisation.js"),
            import("../data/teams.js"),
            import("../data/routes.js"),
            import("../data/modules.js"),
            import("../data/nightnurse.js"),
        ])

        const data = await organisation.getObjectAndID(currentObject, props.router.params.id);
        const routes = await routesData.getRoutes();
        const teams = await teamsData.getTeams();
        const objectsData = await objects.getObjects();
        const devices = await objects.getObjectDevices(props.router.params.id);
        const conf = await organisation.getConfig(props.router.params.id);
        const customDestination = conf.filter(dest => dest.objecttypeparameter === 10 || dest.objecttypeparameter === 11 || dest.objecttypeparameter === 12);
        const msg = await organisation.getOutGoingMessages();
        const table = await organisation.getTimetables();
        const message = await organisation.getMessage(props.router.params.id);
        const roles = await modules.getRoles();
        const nnlocations = await nightnurse.getLocations();
        var webroleExists = conf.find((param) => param.objecttypeparameter === 4);
        console.log(nightnurse.getKeplerStreams('Blijendaal'));
        setWebrole(webroleExists ? webroleExists : []);

        console.log(nnlocations);

        setItem(data[0]);
        setObject(currentObject);
        setRoutes(routes);
        setTeams(teams);
        setLocations(objectsData);
        setDevices(devices);
        setDetailconf(conf);
        setOutgoingmsg(msg);
        setTimetables(table);
        setMessages(message);
        setRoles(roles);
        setNightnurseLocations(nnlocations);
    }

    var count = 0;

    const mapping = {
        location: { OrgID: 1, ObjectID: item.locationid, ObjectName: item.locationname },
        building: { OrgID: 2, ObjectID: item.buildingid, ObjectName: item.buildingname },
        floor: { OrgID: 3, ObjectID: item.floorid, ObjectName: item.floorname },
        room: { OrgID: 4, ObjectID: item.roomid, ObjectName: item.roomname },
        employee: { OrgID: 5, ObjectID: item.employeeid, ObjectName: item.employeename },
        client: { OrgID: 6, ObjectID: item.clientid, ObjectName: item.clientname },
        sharedspace: { OrgID: 6, ObjectID: item.spaceid, ObjectName: item.spacename }
    };

    const OrgID = currentObject ? mapping[currentObject].OrgID : '';
    const ObjectName = currentObject ? mapping[currentObject].ObjectName : '';
    const ObjectID = currentObject ? mapping[currentObject].ObjectID : '';

    $('.showCustomDest').toggle(OrgID === 4 && customDestination);

    var custDestActive, custDest1, custDest2;
    custDestActive = customDestination === 1 ? true : false;

    console.log(props.router.params.id, props.router.params.objectid);

    useEffect(() => {
        getData();
    }, []);

    return (
      <React.Fragment>
        <div className='loc_content'>
        <div class="notice-dashboard-reset">
          {t('resetdashboardtext')}
        </div>
        <form type="POST" onSubmit={(event) => changeData(event)}>
          <input className="btn btn-save" style={{ marginTop: '0px' }} type="submit" name="submit" value={t('save')} />
          {props.router.params.objectid === "5" ? <div style={{ marginTop: '0px' }} onClick={() => { resetDashboard(props.router.params.id, ObjectName) }} class="btn btn-reset-dashboard">{t('resetdashboard')}</div> : ""}
          <h1><div style={{ cursor: 'pointer', display: 'inline-block' }} className="goBackBtn" onClick={() => navigate(`/manage/organisation/object/${props.router.params.objectid}`)}><i class="fas fa-arrow-circle-left"></i></div> {t('configuration')}</h1>

          <div className="alert alert-success alert-update" role="alert">
              {t('updateorgitem')}
          </div>

          <div class="alert alert-danger" role="alert">
              {t('somethingwrong')}
          </div>

          <div className="orgDetails">
            <table className="table">
              <tr className="roweven">
                <td className="td">{t('name')}</td>
                <td className="td"><input type="text" name="name" id="name" defaultValue={ObjectName} className="form-control" /></td>
              </tr>

              { props.router.params.objectid > 1 && (
              <tr className="roweven">
                <td className="td" style={{ verticalAlign: 'top' }}>{t('location')}</td>
                <td className="td">
                  <ObjectLocation relatedid={item.relatedid} type="organisation" objectid={props.router.params.objectid} />
                </td>
              </tr>
              )}
              
              <tr className="roweven">
                <td className="td">{t('route')}</td>
                <td className="td">
                  <select name="route" id="route" className="form-control">
                    <option value="0">{t('select')}</option>
                    {routes.map((route, i) => {
                      var selected = false;

                      if (item.routeid === route.routeid) {
                        selected = true;
                      }

                      return (<option value={route.routeid} selected={selected}>{route.routename}</option>)
                    })}
                  </select>
                </td>
              </tr>
            </table>
          </div>

        </form>

        <h2 className="showconf">{t('detailconfiguration')}</h2>
        <div className="orgDetails showconf">
          <table className="table">
            {detailconf.map((conf, i) => {
              count = count + 1;

              if(conf.name === "KeplerID"){
                return (
                  <tr>
                    <td className="td">{conf.name}</td>
                    <td className="td">
                      <select onChange={(event) => { updateOrgConffield(props.router.params.id, conf.name, conf.objectparameterid, event.target.value) }} className="form-control" name={`param${count}Val`} id={`param${count}Val`} key="nnloc" defaultValue={conf.value}>
                      <option key="0" value="0">{t('select')}</option>
                      {console.log(conf)}
                        {nightnurseLocations.map((location, i) => {
                          return(<option key={i} value={location.displayName}>{location.displayName}</option>)
                        })}
                      </select>
                    </td>
                  </tr>
                )
              }

              if(conf.name !== 'Webrole'){
                return (
                  <tr>
                    <td className="td">{conf.name}</td>
                    <td className="td">
                      <input type="hidden" name={`param${count}`} id={`param${count}`} defaultValue={conf.objectparameterid} className="form-control" />
                      <input type="text" onChange={(event) => { updateOrgConffield(props.router.params.id, conf.name, conf.objectparameterid, event.target.value) }} name={`param${count}Val`} id={`param${count}Val`} defaultValue={conf.value} className="form-control" />
                    </td>
                  </tr>
                )
              }
            })}

            <tr>
                  <td className="td">{t('permissions')}</td>
                  <td className="td">
                    <select onChange={(event) => { setUserWebrole(props.router.params.id, ObjectName, 4, event.target.value) }} key={count} name={`param${count}`} id={`param${count}`} defaultValue={webrole && webrole.value ? webrole.value : 0} className="form-control">
                      {roles.map(role => {
                        if(role.deleted === 0){
                          return <option value={role.id}>{t(`${role.slug}`)}</option>
                        }
                      })}
                    </select>
                  </td>
              </tr>
          </table>
        </div>

        <h2 class="showCustomDest">{t('destination')}</h2>
        <div className="orgDetails showCustomDest">
          <div className="destination">
            <div className="row">
              <div class="col-md-2">
                <input className="checkCustomDestination" defaultChecked={custDestActive} name="checkCustomDestination" data-object="{{ Organization.CustomDestination[0].objectid }}" data-id="{{ Organization.CustomDestination[0].objectparameterid }}" type="checkbox" /> {t('activate')}
              </div>
              <div className="col-md-5">
                <select className="form-control CustomDestination1" name="CustomDestination1" data-object="{{ Organization.CustomDestination[1].objectid }}" data-id="{{ Organization.CustomDestination[1].objectparameterid }}">
                  <option value="0">{t('select')}</option>
                  {locations.map((location, i) => {

                    if (customDestination1 === location.objectid) {
                      custDest1 = true;
                    } else {
                      custDest1 = false;
                    }

                    return (<option value={location.objectid} selected={custDest1}>{location.objectname}</option>)
                  })}
                </select>
              </div>
              <div className="col-md-5">
                <select className="form-control CustomDestination2" name="CustomDestination2" data-object="{{ Organization.CustomDestination[2].objectid }}" data-id="{{ Organization.CustomDestination[2].objectparameterid }}">
                  <option value="0"><option value="0">{t('select')}</option></option>
                  {teams.map((team, i) => {

                    if (customDestination2 === team.teamid) {
                      custDest2 = true;
                    } else {
                      custDest2 = false;
                    }

                    return (<option value={team.teamid} selected={custDest2}>{team.name}</option>)
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>

        <h2 className="showdevices">{t('linkeddevices')}</h2>
        <div className="orgDetails showdevices">

          <table className="table">
            {devices.map((device, i) => {
                return (props.router.params.id == device.locationid ? <tr>
                  <td>{device.devicename}</td>
                  <td>{device.devicetypename}</td>
                </tr> : '')
            })}
          </table>
        </div>

        <form type="POST" onSubmit={(event) => changeRules(event, item.employeename)}>
          <input style={{ marginTop: '0px' }} className="btn btn-save" type="submit" name="submit" value={t('save')} />
          <h2>{t('messages')}</h2>

          <div className="orgDetails">

            <table className="table">
              <tr>
                <th>{t('message')}</th>
                <th>{t('message')}</th>
                <th>{t('route')}</th>
                <th>{t('timescheme')}</th>
                <th>{t('onoff')}</th>
                <th style={{ textAlign: 'center' }}>{t('active')}</th>
                <th></th>
              </tr>

              {messages.map((message, i) => {

                var checkedEnabled, checkedActive;

                if(message.active === 1){
                  checkedActive = true;
                }else{
                  checkedActive = false;
                }

                if(message.enabled === 1){
                  checkedEnabled = true;
                }else{
                  checkedEnabled = false;
                }

                return (
                  <tr>
                    <td className="td">{message.name}</td>
                    <td className="td">
                      <select defaultValue={message.outgoingmessage} data-out={message.messageid} name={`outgoingMsg${message.id}`} id={`outgoingMsg_${message.id}`} className="form-control">
                        <option value="1">{t('select')}</option>
                        {outgoingmsg.map((outmsg, i) => {
                          return (<option value={outmsg.id}>{outmsg.name}</option>)
                        })}
                      </select>
                    </td>
                    <td className="td">
                      <select defaultValue={message.outgoingrouteid} name={`outgoingRoute${message.id}`} id={`outgoingRoute_${message.id}`} className="form-control">
                        <option value="0">{t('select')}</option>
                        {routes.map((route, i) => {
                          return (<option value={route.routeid}>{route.routename}</option>)
                        })}
                      </select>
                    </td>
                    <td className="td">
                      <select defaultValue={message.timetableid} data-table={message.timetableid} name={`timetable${message.id}`} id={`timetable_${message.id}`} className="form-control">
                        <option value="0">{t('select')}</option>
                        {timetables.map((time, i) => {
                          return (<option value={time.id} >{time.name}</option>)
                        })}
                      </select>
                    </td>
                    <td>
                      <label className='switch'>
                        <input name={`enabled${message.id}`} id={`enabled_${message.id}`} defaultChecked={checkedEnabled} key={`${Math.floor((Math.random() * 1000))}-min`} type='checkbox' />
                        <span className='slider round'></span>
                      </label>
                    </td>
                    <td>
                      <label className='switch'>
                        <input name={`active${message.id}`} id={`active_${message.id}`} defaultChecked={checkedActive} key={`${Math.floor((Math.random() * 1000))}-min`} type='checkbox' />
                        <span className='slider round'></span>
                      </label>
                    </td>
                    <td style={{ width: '100px', textAlign: 'center' }}>
                    {message.outgoingmessage && message.outgoingmessage !== '' ? (<i style={{ cursor: 'pointer', background: '#FF0000', borderRadius: '7px', color: '#FFFFFF', fontSize: '14px' }} class="fas fa-trash-alt" onClick={() => confirmDelete(function () { delResponseRule(message.ruleid) })}></i> ) : ''}
                    </td>
                  </tr>
                )
              })}
            </table>
          </div>
        </form>
        </div>

        <ModalDelete />
      </React.Fragment>
    );
}

export default withRouter(OrganisationDetails);
import Config from "../config.json";

export const getIntPhonebook = (employeeid) => {
    if(employeeid && employeeid !== ''){
        var url = Config.API_URL + "/mba/phonebook/get/" + employeeid;
    }else{
        url = Config.API_URL + "/mba/phonebook/get/";
    }
    return fetch(url, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "contactid": employeeid ? employeeid : '',
            "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getTeamsOfContact = (contactid) => {
    return fetch(Config.API_URL + `/phonebook/get/${contactid}/teams`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "contactid": contactid,
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getDeviceofUser = (deviceid) => {
    return fetch(Config.API_URL + `/mba/phonebook/device/${deviceid}`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "deviceid": deviceid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getExtPhonebook = () => {
    return fetch(Config.API_URL + "/mba/phonebook/external/get", {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : ''
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const getExtContact = (contactid) => {
    return fetch(Config.API_URL + `/mba/phonebook/external/contact/${contactid}`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "contactid": contactid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const addExtContact = (name, phonenumber, team) => {
    return fetch(Config.API_URL + `/mba/phonebook/external/insert`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "name": name,
            "phonenumber": phonenumber,
            "teamid": team,
            "locationid": localStorage.getItem('currentLocation') ? localStorage.getItem('currentLocation') : 0
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const updateExtContact = (contactid, name, phonenumber, teamid) => {
    return fetch(Config.API_URL + `/mba/phonebook/external/update/${contactid}`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "name": name,
            "phonenumber": phonenumber,
            "teamid": teamid,
            "contactid": contactid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const delExtContact = (contactid) => {
    return fetch(Config.API_URL + `/mba/phonebook/external/delete/${contactid}`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "contactid": contactid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}
import React, { Component, useEffect, useState } from 'react';
import './Manage.css';
import '../components/DataTables.css';
import withRouter from '../components/withRouter';
import { initDataTable, bulkSelect, confirmDelete, crudAlertControl } from "../Functions.js";
import ModalDelete from "../components/ModalDelete";
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
const $ = require('jquery');

const Devices = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [devices, setDevices] = useState([]);
    const [locations, setLocations] = useState([]);
    const [type, setType] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const bulk = [];

    const getData = async (init) => {
        const [devicesData, objectsData] = await Promise.all([
            import("../data/devices.js"),
            import("../data/objects.js")
        ])

        const type = await devicesData.getDeviceTypes(props.router.params.id);
        const data = await devicesData.getDevices();
        const objects = await objectsData.getObjects();
        const DeviceArray = data.filter(device => device.devicetypeid === parseInt(props.router.params.id));

        setType(type[0]);
        setDevices(DeviceArray);
        setLocations(objects);
        initDataTable();
    }

    const addDevice = async (event) => {
        event.preventDefault();

        const devicesData = await import("../data/devices.js");
        const data = await devicesData.insertDevice(event.target.name.value, props.router.params.id, event.target.location.value);

        if(data.length > 0 && data[0] && data[0].deviceid && event.target.location.value){
            const settings = await import('../data/settings.js');
            const mbasettings = await settings.getMbaTemplates();

            if(mbasettings[0].value === '1'){
                const responserule = await import("../mba/data/ResponseRules");
                const rule = await responserule.insertFromTemplate(data[0].deviceid, props.router.params.id, event.target.location.value);
            }
            
        }

        getData();
        crudAlertControl('add', data);
        setShowModal(false);
    }

    const delDevice = async (row, objectID, devicename) => {
        const devicesData = await import("../data/devices.js");
        const data = await devicesData.deleteDevice(objectID, devicename);

        const settings = await import('../data/settings.js');
        const mbasettings = await settings.getMbaTemplates();

        if(data === "OK" && mbasettings[0].value === '1'){
            const responserule = await import("../mba/data/ResponseRules");
            const rule = await responserule.deleteFromDevice(objectID);
        }
        
        getData();
        crudAlertControl('delete', data);
    }

    const bulkDeleteDevice = async (event, array) => {
        const devicesData = await import("../data/devices.js");
        array.forEach(async (device) => {
            const data = await devicesData.deleteDevice(device.id, device.name);
            getData();
            crudAlertControl('delete', data);
        })
    }

    const bulkChangeLocation = async (event, array) => {
        const devicesData = await import("../data/devices.js");
        array.forEach(async (device) => {
            const data = await devicesData.changeLocation(device.id, device.name, event.target.value);
            getData();
        })
    }

    const modalShow = () => {
        setShowModal(true);
    }

    const modalHide = () => {
        setShowModal(false);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <div onClick={() => modalShow()} className="btn btn-add">{t('add')}</div>
                <h1><button className="goBackBtn" onClick={() => navigate('/manage/devices/list/all')}><i class="fas fa-arrow-circle-left"></i></button> {type.devicetypename}</h1>
                

                <div className="alert alert-success alert-add" role="alert">
                    {t('newdevice')}
                </div>

                <div className="alert alert-success alert-delete" role="alert">
                    {t('deldevice')}
                </div>

                <div class="alert alert-danger" role="alert">
                    {t('somethingwrong')}
                </div>

                <div className="DataTable_Container">
                    <div class="bulk" style={{ marginTop: '20px' }}>
                        <span style={{ color: '#000000', marginRight: '10px' }}>{t('bulkselect')}: </span>
                        <select style={{ marginRight: '10px' }} onChange={(event) => bulkChangeLocation(event, bulk)}>
                            <option value="0">{t('select')}</option>
                                {locations.map((location, i) => {
                                    return (
                                        <option value={location.objectid}>{location.objectname}</option>
                                        )
                                })}
                        </select>
                        <i onClick={(event) => confirmDelete(t('delitem'), function () { bulkDeleteDevice(event, bulk) })} className="btn-delete fas fa-trash-alt"></i>
                    </div>
                    <table id="DataTable" className="showHead table responsive nowrap" width="100%">
                        <thead>
                            <tr className="rowtitle">
                                <th></th>
                                <th>{t('name')}</th>
                                <th>{t('type')}</th>
                                <th>{t('location')}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {devices.map((device, i) => {
                                return (
                                    <tr key={i}>
                                        <td style={{ width: '1px' }} data-id={device.deviceid} data-name={device.devicename}><input type="checkbox" className="bulkSelect" onChange={(event) => bulkSelect(event, bulk)} /></td>
                                        <td onClick={(event) => { navigate(`/manage/device/${device.deviceid}`) }}>{device.devicename}</td>
                                        <td>{device.devicetype}</td>
                                        <td>{device.locationname}</td>
                                        <td><i onClick={(event) => confirmDelete(t('delitem'), function () { delDevice(event, device.deviceid, device.devicename) })} className="btn-delete fas fa-trash-alt"></i></td>
                                    </tr>
                                    )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <ModalDelete />

            {showModal && ( <div style={{ display: 'block' }} id="add" className="modal fade in">
                <div className="modal-content ">
                    <div className="modal-header">
                        <h4 className="modal-title">{t('adddevice')}</h4>
                        <div onClick={() => modalHide()} className="close">&times;</div>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={(event) => addDevice(event)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="idname">{t('name')}</label>
                                        <input type="text" name="name" id="name" className="form-control" required />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label for="location">{t('location')}</label>
                                        <select name="Location" id="location" className="form-control" required>
                                            <option value="">{t('select')}</option>
                                            {locations.map((location, i) => {
                                                return (<option value={location.objectid}>{location.objectname}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div onClick={() => modalHide()} id="cancel" className="btn btn-red cancel">{t('cancel')}</div>
                                </div>
                                <div className="col-md-6">
                                    <button type="submit" className="btn btn-green">{t('add')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div> )}
        </React.Fragment>
        );
}

export default withRouter(Devices);

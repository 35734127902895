import React from 'react';
import withRouter from '../components/withRouter';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import * as xlsx from 'xlsx';
import * as autoTable from 'jspdf-autotable';

const Export = (props) => {
    const {t} = useTranslation();

    const exportToPDF = () => {
        const pdf = new jsPDF('l', 'pt', 'a4');
        pdf.autoTable({ 
            html: "#DataTable",
            columnStyles: {
                2: {
                  cellWidth: 'auto'
                }
              }
        });
        pdf.save("chc_data_export.pdf")
    }

    const exportToExcel = () => {
        const ws = xlsx.utils.table_to_sheet(document.getElementById('DataTable'));
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
        xlsx.writeFile(wb, 'chc_data_export.xlsx');
    }

    return (
        <React.Fragment>
            <div style={{ float: 'right', marginTop: '5px' }} className='export_to_file'>
                <span style={{ verticalAlign: 'super', marginRight: '10px' }}>Exporteren:</span>
                <button style={{ background: 'transparent', border: 'none' }} onClick={() => exportToPDF()}><i style={{ color: 'red', fontSize: '25px' }} class="fas fa-file-pdf"></i></button>
                <button style={{ background: 'transparent', border: 'none' }} onClick={() => exportToExcel()}><i style={{ color: 'darkgreen', fontSize: '25px' }} class="fas fa-file-excel"></i></button>
            </div>
        </React.Fragment>
    );
}

export default withRouter(Export);

import React, { useState, useEffect } from 'react';
import withRouter from '../components/withRouter';
import './Manage.css';
import { crudAlertControl } from '../Functions';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

const LanguageDetails = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [language, setLanguage] = useState([]);

    const getData = async () => {
        const languagesData = await import("../data/languages.js");
        const data = await languagesData.getLanguage(props.router.params.id);
        setLanguage(data[0]);
    }

    const changeData = async (event) => {
        event.preventDefault();
        const languagesData = await import("../data/languages.js");
        const data = await languagesData.updateLanguage(props.router.params.id, event.target.code.value, event.target.name.value, event.target.countrycode.value, event.target.active.checked ? 1 : 0);
        crudAlertControl('update', data);
        await getData();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>

            <div className="alert alert-success alert-update" role="alert">
                {t('updatelanguage')}
            </div>

            <div class="alert alert-danger" role="alert">
                {t('somethingwrong')}
            </div>

            <form type="POST" onSubmit={(event) => changeData(event)}>
                <input className="btn btn-save" style={{ marginTop: '0px' }} type="submit" name="submit" value="Opslaan" />
                <h2><button className="goBackBtn" onClick={() => navigate('/manage/languages')}><i class="fas fa-arrow-circle-left"></i></button> {language.name}</h2>

                <div class="teamDetails">
                    <table class="table">
                        <tr class="roweven">
                            <td class="td">{t('code')}</td>
                            <td class="td"><input key={`${Math.floor((Math.random() * 1000))}-min`} type="text" name="code" id="code" defaultValue={language.code} className="form-control" /></td>
                        </tr>
                        <tr class="rowodd">
                            <td class="td">{t('name')}</td>
                            <td class="td"><input key={`${Math.floor((Math.random() * 1000))}-min`} type="text" name="name" id="name" defaultValue={language.name} className="form-control" /></td>
                        </tr>
                        <tr class="rowodd">
                            <td class="td">{t('countrycode')}</td>
                            <td class="td"><input key={`${Math.floor((Math.random() * 1000))}-min`} type="text" name="countrycode" id="countrycode" defaultValue={language.country_code} className="form-control" /></td>
                        </tr>
                        <tr class="rowodd">
                            <td class="td">{t('active')}</td>
                            <td class="td">
                                <label className='switch'>
                                    <input name="active" id="active" key={`${Math.floor((Math.random() * 1000))}-min`} defaultChecked={language.active === 1 ? "true": ""} type='checkbox' />
                                    <span className='slider round'></span>
                                </label>
                            </td>
                        </tr>
                    </table>
                </div>
            </form>
        </React.Fragment>
    )
}

export default withRouter(LanguageDetails);
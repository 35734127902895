import React, { Component, useEffect, useState } from 'react';
import withRouter from '../components/withRouter';
import IconPicker from '../components/IconPicker.js';
import './Manage.css';
import { useNavigate } from 'react-router';
import { crudAlertControl } from '../Functions';
import LocationAlert from '../components/LocationAlert';
import { useTranslation } from 'react-i18next';

const ModuleDetail = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [categories, setCategories] = useState([]);
    const [module, setModule] = useState([]);
    const [roles, setRoles] = useState([]);

    const getData = async () => {
        const modules = await import("../data/modules.js");
        const modulesData = await modules.getModules(props.router.params.id);
        const categoriesData = await modules.getCategories();
        const rolesData = await modules.getRoles();

        setModule(modulesData[0]);
        setCategories(categoriesData);
        setRoles(rolesData);
    }

    const changeData = async (event) => {
        event.preventDefault();
        const modules = await import("../data/modules.js");
        const updateData = await modules.updateModule(
            props.router.params.id, 
            event.target.moduleicon.value, 
            event.target.moduleurl.value, 
            event.target.modulecat.value, 
            event.target.moduledesc.value
        )
          
        crudAlertControl('update', updateData);
        await getData();
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className="loc_content">
                <form type="POST" onSubmit={(event) => changeData(event)}>
                    <input className="btn btn-save" style={{ marginTop: '0px', color: '#fff' }} type="submit" name="submit" value={t('save')} />
                    <h1><button className="goBackBtn" onClick={() => navigate('/manage/modules')}><i class="fas fa-arrow-circle-left"></i></button> {t(`${module.slug ? module.slug : module.name}`)}</h1>

                    <div className="alert alert-success alert-update" role="alert">
                        {t('updatemodule')}
                    </div>

                    <div class="alert alert-danger" role="alert">
                        {t('somwthingwrong')}
                    </div>

                    <div class="orgDetails">
                        <table class="table">
                            <tr class="rowodd">
                                <td class="td">{t('moduleicon')}</td>
                                <td class="td"><IconPicker currenticon={module.icon}/></td>
                            </tr>
                            <tr class="rowodd">
                                <td class="td">{t('moduleurl')}</td>
                                <td class="td"><input type="text" name="moduleurl" id="moduleurl" defaultValue={module.url} className="form-control" /></td>
                            </tr>
                            <tr class="rowodd">
                                <td class="td">{t('category')}</td>
                                <td class="td">
                                    <select defaultValue={module.categorie} name="modulecat" id="modulecat" class="form-control">
                                        <option value="0">{t('select')}</option>
                                            {categories.map((category, i) => {
                                                var selected = module.categorie === category.id ? true : false;
                                                return (<option selected={selected} value={category.id}>{t(`${category.slug}`)}</option>)
                                            })}
                                        </select>
                                </td>
                            </tr>
                            <tr class="rowodd">
                                <td class="td">{t('description')}</td>
                                <td class="td"><textarea name="moduledesc" id="moduledesc" defaultValue={module.description} className="form-control" /></td>
                            </tr>
                        </table>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}

export default withRouter(ModuleDetail);
import React, { Component, useEffect, useState } from 'react';
import './Reports.css';
import '../../components/DataTables.css';
import withAuth from '../../components/Login/withAuth';
import * as moment from 'moment';
import { initDataTable, fillDataTableFilters, filterDataTable } from "../../Functions.js";
import withRouter from '../../components/withRouter';
import { registerLocale } from "react-datepicker";
import DatePicker from "react-datepicker";
import LocationAlert from "../../components/LocationAlert.js";
import "react-datepicker/dist/react-datepicker.css";
import nl from 'date-fns/locale/nl';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
registerLocale('nl', nl);
moment.locale('nl');

const AlarmLogging = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [logging, setLogging] = useState([]);
    const [users, setUsers] = useState([]);
    const [objects, setObjects] = useState([]);
    const [modules, setModules] = useState([]);
    var currentdate = new Date(props.router.params.reportdate);

    const getAlarmLogging = async (date) => {
        const alarms = await import("../../data/alarms.js");
        const data = await alarms.getLogging(date);

        document.getElementById('objectAlarm').innerHTML = "<option value='0'>Selecteer object</option>";
        document.getElementById('userAlarm').innerHTML = "<option value='0'>Selecteer gebruiker</option>";

        data.forEach(item => {
            fillDataTableFilters(document.getElementById('objectAlarm'), item.object);
            fillDataTableFilters(document.getElementById('userAlarm'), item.user);
        })

        setLogging(data);
        initDataTable();
    }

    const goToDate = (date) => {
        navigate('/reports/alarmlogging/' + moment(date).format('YYYY-MM-DD'));
        getAlarmLogging(moment(date).format('YYYY-MM-DD'));
    }

    useEffect(() => {
        getAlarmLogging(props.router.params.reportdate);
    }, [])

    return (
        <React.Fragment>
            <LocationAlert />

            <div className='loc_content'>
                <h1>{t('alarmlogging')}</h1>
                <div class="filters">
                    {t('date')}: <DatePicker locale="nl" dateFormat="yyyy-MM-dd"
                        selected={currentdate}
                        onChange={(data, e) => goToDate(data)}
                    />
                    {t('object')}:
                    <select id="objectAlarm" onChange={(event) => filterDataTable("reporthistoryalarm-alarmobject", 2, event.target.value, false)}>
                        <option data-id="0" value="">{t('select')}</option>
                    </select>

                    {t('alarm')}:
                    <select id="alarm" onChange={(event) => filterDataTable("reporthistoryalarm-alarmid", 1, event.target.value, false)}>
                        <option data-id="0" value="">{t('select')}</option>
                    </select>

                    {t('user')}:
                    <select id="userAlarm" onChange={(event) => filterDataTable("reporthistoryalarm-alarmuser", 3, event.target.value, false)}>
                        <option data-id="0" value="">{t('select')}</option>
                    </select>

                    <div id="buttons" style={{ float: 'right' }}></div>
                </div>

                {module.description && module.description !== '' ?
                    <div className="help">
                        {module.description}
                    </div> : ''}

                    <div className="DataTable_Container">
                        <table id="DataTable" class="showHead table responsive nowrap" width="100%">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('alarm')}</th>
                                    <th>{t('object')}</th>
                                    <th>{t('user')}</th>
                                    <th>{t('date')}/{t('time')}</th>
                                    <th>{t('active')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logging.map((log, i) => {

                                    fillDataTableFilters(document.getElementById('objectAlarm'), log.object);
                                    fillDataTableFilters(document.getElementById('userAlarm'), log.user);
                                    fillDataTableFilters(document.getElementById('alarm'), log.alarm);

                                    var dotColor = log.action === 0 ? '#FF0000' : '#00FF00';

                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{log.alarm}</td>
                                            <td>{log.object}</td>
                                            <td>{log.user}</td>
                                            <td>{moment(log.dateaction).format('DD-MM-YYYY HH:mm:ss')}</td>
                                            <td><div style={{ width: '10px', height: '10px', background: dotColor, borderRadius: '100%' }}></div></td>
                                        </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(AlarmLogging);

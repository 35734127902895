import React, { useEffect, useState } from 'react';
import withAuth from '../components/Login/withAuth';
import Menu from './components/Menu';
import { checkMBAUserPermission, crudAlertControl } from './Functions';
import withRouter from '../components/withRouter';

const MessageDetail = (props) => {

    const [message, setMessage] = useState([]);
    const [connections, setConnections] = useState([]);
    const [types, setTypes] = useState([]);
    const [descriptions, setDescriptions] = useState([]);

    const changeMessage = async (event) => {
        event.preventDefault();

        var msgname = event.target.msgname.value;
        var msgconnection = event.target.connection.value;
        var msgtype = event.target.type.value;
        var msgdesc = event.target.description.value;
        var msgcontent = event.target.msgcontent.value ? event.target.msgcontent.value : null;
        var msgincoming = event.target.incoming.checked ? 1 : 0;
        var msgoutgoing = event.target.outgoing.checked ? 1 : 0;
        var msgreport = event.target.report.checked ? 1 : 0;

        const messages = await import("./data/Messages");
        const data = await messages.updateMsg(props.router.params.id, msgname, msgcontent, msgconnection, msgtype, msgdesc, msgincoming, msgoutgoing, msgreport);
        crudAlertControl('update', data);
    }

    const getData = async () => {
        const [responserules, messages] = await Promise.all([
			import("./data/ResponseRules"),
			import("./data/Messages"),
		])

		const conndata = await responserules.getConnections();
        const types = await messages.getMessageTypes();
        const descriptions = await messages.getMessageDescriptions();
        const data = await messages.getMessage(props.router.params.id);
        setConnections(conndata);
        setTypes(types);
        setDescriptions(descriptions);
        setMessage(data[0]);
    }
    
    useEffect(() => {
        getData();
    }, [])

    return(
        <React.Fragment>
	        <Menu />

			<div className="alert alert-success alert-update" role="alert">
                Message gewijzigd.
            </div>

            <div className="alert alert-danger" role="alert">
                Er is iets niet goed gegaan. Probeer het later nog eens.
            </div>

			<form onSubmit={(event) => changeMessage(event)}>
                <input className="btn btn-save" style={{ marginTop: '0px', color: '#fff' }} type="submit" name="submit" value="Opslaan" />
                <h2>Message details</h2>

                <div className="teamDetails">
                    <table className="table responsive nowrap" width="100%">
                        <tr class="roweven">
                            <td class="td">Naam</td>
                            <td class="td"><input type="text" name="msgname" id="msgname" defaultValue={message.name} className="form-control" /></td>
                        </tr>
                        <tr class="rowodd">
                            <td class="td">Connection</td>
                            <td class="td">
                                <select id="connection" defaultValue={message.connectionid} key={`${Math.floor((Math.random() * 1000))}-min`} name="connection" className='form-control'>
									<option value="0">Selecteer connection</option>
									{connections.map((connection, i) => {
										return <option selected={message.connectionid} key={i} value={connection.id}>{connection.name}</option>;
									})}
								</select>
                            </td>
                        </tr>
                        <tr class="rowodd">
                            <td class="td">MessageType</td>
                            <td class="td">
                                <select id="type" defaultValue={message.messagetypeid} key={`${Math.floor((Math.random() * 1000))}-min`} name="type" className='form-control'>
									<option value="0">Selecteer type</option>
									{types.map((type, i) => {
										return <option selected={message.messagetypeid} key={i} value={type.id}>{type.name}</option>;
									})}
								</select>
                            </td>
                        </tr>
                        <tr class="rowodd">
                            <td class="td">Content</td>
                            <td class="td"><textarea name="msgcontent" id="msgcontent" defaultValue={message.content} className="form-control"></textarea></td>
                        </tr>
                        <tr class="rowodd">
                            <td class="td">Message description</td>
                            <td class="td">
                                <select id="description" defaultValue={message.descriptionid} key={`${Math.floor((Math.random() * 1000))}-min`} name="description" className='form-control'>
									<option value="0">Selecteer description</option>
									{descriptions.map((desc, i) => {
										return <option selected={message.descriptionid} key={i} value={desc.id}>{desc.name}</option>;
									})}
								</select>
                            </td>
                        </tr>
                        <tr class="rowodd">
                            <td class="td">Incoming message</td>
                            <td class="td">
                                <label className='switch'>
                                    <input defaultChecked={message.incoming === 1 ? "true" : "" } key={`${Math.floor((Math.random() * 1000))}-min`} name="incoming" id="incoming" type='checkbox' />
                                    <span className='slider round'></span>
                                </label>
                            </td>
                        </tr>
                        <tr class="rowodd">
                            <td class="td">Outgoing message</td>
                            <td class="td">
                                <label className='switch'>
                                    <input defaultChecked={message.outgoing === 1 ? "true" : "" } key={`${Math.floor((Math.random() * 1000))}-min`} name="outgoing" id="outgoing" type='checkbox' />
                                    <span className='slider round'></span>
                                </label>
                            </td>
                        </tr>
                        <tr class="rowodd">
                            <td class="td">Report message</td>
                            <td class="td">
                                <label className='switch'>
                                    <input defaultChecked={message.report === 1 ? "true" : "" } key={`${Math.floor((Math.random() * 1000))}-min`} name="report" id="report" type='checkbox' />
                                    <span className='slider round'></span>
                                </label>
                            </td>
                        </tr>
                    </table>
                </div>
            </form>
        </React.Fragment>
    )

}

export default withRouter(MessageDetail);
import React, { Component, useEffect, useState } from 'react';
import withRouter from '../components/withRouter';
import { useTranslation } from 'react-i18next';
import { confirmDelete } from '../Functions.js';
import * as moment from 'moment';
moment.locale('nl');

const AlarmNotes = (props) => {
    var currentid = props.alarm ? (props.alarm.objectalarmid ? props.alarm.objectalarmid : props.alarm.objectid) : 0;
    var locationid = props.alarm ? props.alarm.locationid : 0;
    const { t } = useTranslation();
    const [alarmnotes, setAlarmnotes] = useState([]);

    const addNote = async (event) => {
        event.preventDefault();
        const data = await import('../data/notes.js');
        const note = await data.insertNote(currentid, locationid, event.target.note.value);
    
        document.getElementById('note').value = '';
        await getData();
      }
    
    const delNote = async (noteid) => {
        const data = await import('../data/notes.js');
        const note = await data.deleteNote(noteid);
        await getData();
    }

    const getData = async () => {
        const alarms = await import("../data/alarms.js");
        const getAlarmNotes = await alarms.getAlarmNotes(currentid);
        setAlarmnotes(getAlarmNotes);
    }

    useEffect(() => {
        getData();
    }, [])
    
    return (
        <React.Fragment>
            <div className='alarmnotes'>
                {/* <h2 style={{ marginLeft: '10px', marginBottom: '20px' }}>{t('notes')}</h2> */}
                {alarmnotes.map(note => {
                  return(
                    <React.Fragment>
                      <div className="card" style={{ width: '92%', margin: '0 0 20px 10px' }}>
                        {!props.alarm.finishtriggered && note.userid == localStorage.getItem('UserID') && (<i onClick={(event) => confirmDelete(t('delitem'), function () { delNote(note.id) })} style={{ float: 'right', cursor: 'pointer' }} className='btn-delete fa fa-trash'></i>)}
                        <div className='note_content' style={{ fontSize: '14px' }}>{note.content}</div>
                        <div className='note_info' style={{ color: '#888', marginTop: '5px' }}>{note.user} - {moment(note.datecreated).format("DD-MM-YY HH:mm:ss")}</div>
                      </div>
                    </React.Fragment>
                  )
                })}

                {!props.alarm.finishtriggered && (
                  <div className='add_note' style={{ width: '92%',margin: '0 0 20px 10px' }}>
                    <form method="POST" onSubmit={(event) => addNote(event)}>
                        <div className="col-md-12" style={{ padding: '0' }}>
                            <div className="form-group">
                                <label for="note" style={{ color: '#000000', marginBottom: '10px' }}>{t('addnote')}</label>
                                <input type="text" name="note" id="note" className="form-control" required />
                            </div>
                            <div className="form-group" style={{ margin: '0' }}>
                              <button type="submit" className="btn btn-green">{t('add')}</button>
                            </div>
                        </div>
                    </form>
                  </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default withRouter(AlarmNotes);

import Config from "../config.json";

export const getOrganisations = () => {
  return fetch(Config.API_URL + "/mba/organization/get", {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getBreadcrumbs = async (object, relatedid, depth = 0) => {
  var structure = [];

  var level0 = await getObjectAndID(object, relatedid);

  if(level0 && level0.length > 0 && depth == 2){
    structure.push({
      floor:{
        floorid: level0[0].floorid,
        floorname: level0[0].floorname,
      },
      building: {
        buildingid: level0[0].relatedid,
        buildingname: level0[0].relatedname
      }
    })
    
    return structure;
  }else if(level0 && level0.length > 0 && depth == 1){
    structure.push({
      location:{
        locationid: level0[0].locationid,
        locationname: level0[0].locationname,
      }
    })
  }

  
  
}

export const getObjectType = (object) => {
  return fetch(Config.API_URL + `/mba/${object}/get`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "locationid": localStorage.getItem('currentLocation'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getObjectAndID = (objectid, id) => {
  return fetch(Config.API_URL + `/mba/${objectid}/get/${id}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const addObject = (objectid, relatedid, name, routeid) => {
  return fetch(Config.API_URL + `/mba/organization/insert`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectid": objectid,
      "relatedid": relatedid,
      "name": name,
      "routeid": routeid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const deleteObject = (objectid, name) => {
  return fetch(Config.API_URL + `/mba/organization/delete/${objectid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectid": objectid,
      "name": name,
    }
  })
  .then(response => response.json())
  .then(data => {
    return data;
  })
}

export const updateObject = (objectid, name, related, route, enabled, active) => {
  return fetch(Config.API_URL + `/mba/organization/update/${objectid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectid": objectid,
      "name": name,
      "relatedid": related,
      "routeid": route,
      "enabled": enabled,
      "active": active,
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const updateLocation = (objectid, locationid) => {
  return fetch(Config.API_URL + `/mba/organization/update/${objectid}/location`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectid": objectid,
      "locationid": locationid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const updateRoute = (objectid, routeid) => {
  return fetch(Config.API_URL + `/mba/organization/update/${objectid}/route`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectid": objectid,
      "routeid": routeid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getConfig = (objectid) => {
  return fetch(Config.API_URL + `/mba/organization/detailconfig/` + objectid, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectid": objectid,
    }
  })
  .then(response => response.json())
  .then(data => {
    return data;
  })
}

export const getIncomingMessages = () => {
  return fetch(Config.API_URL + `/mba/organization/incomingmsgs/`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/json",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID')
    }
  })
    .then(response => response.json())
    .then(msg => {
      return msg;
    })
}

export const getOutGoingMessages = () => {
  return fetch(Config.API_URL + `/mba/organization/outgoingmsgs/`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/json",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID')
    }
  })
    .then(response => response.json())
    .then(msg => {
      return msg;
    })
}

export const getMessage = (msgid) => {
  return fetch(Config.API_URL + `/mba/organization/messages/${msgid}`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/json",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectid": msgid
    }
  })
    .then(response => response.json())
    .then(message => {
      return message
    })
}

export const getRowsOfMessage = (objectid, msgid) => {
  return fetch(Config.API_URL + `/mba/organization/messages/${objectid}/rows`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "messageid": msgid,
      'objectid': objectid
    }
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
}

export const getTimetables = () => {
  return fetch(Config.API_URL + `/mba/organization/timetables/`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/json",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID')
    }
  })
    .then(response => response.json())
    .then(timetables => {
      return timetables;
    })
}

export const updateOrgConffield = (objectid, objectname, objectparamid, objectparamvalue) => {
  fetch(Config.API_URL + `/mba/organization/objectparams/update/` + objectid, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectid": objectid,
      "name": objectname,
      'objectparamid': objectparamid,
      'objectparamvalue': objectparamvalue
    }
  })
  .then(response => response.json())
  .then(data => {
    return data;
  })
}

export const addObjectParam = (objectid, objectname, objectparamtype, objectparamvalue) => {
  return fetch(Config.API_URL + `/mba/organization/objectparams/insert`, {
    "method": "GET",
    "headers": {
      "Authorization": "Bearer " + localStorage.getItem('id_token'),
      "Content-Type": "application/x-www-form-urlencoded",
      "userid": localStorage.getItem('UserID'),
      "customerid": localStorage.getItem('CustomerID'),
      "objectid": objectid,
      "name": objectname,
      'objectparamtype': objectparamtype,
      'objectparamvalue': objectparamvalue
    }
  })
  .then(response => response.json())
  .then(data => {
    return data;
  })
}
import React, { Component } from 'react';
import withAuth from '../components/Login/withAuth';
import Menu from './components/Menu';
import { checkMBAUserPermission } from './Functions';
import Config from "../config.json";

class MBADevices extends Component {

    componentDidMount(){
        checkMBAUserPermission();
    }

    render(){
        return(
            <React.Fragment>
                <Menu />
                <h2>Devices</h2>
            </React.Fragment>
        )
    }
}

export default withAuth(MBADevices);
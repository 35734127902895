import React, { Component, useEffect, useState } from 'react';
import './Manage.css';
import '../components/DataTables.css';
import withRouter from '../components/withRouter';
import { updateDeviceConfField } from "../data/devices.js";
import { crudAlertControl } from '../Functions';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import ObjectLocation from '../components/ObjectLocation.js';

const DeviceDetails = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [device, setDevice] = useState([]);
    const [locations, setLocations] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [statusses, setStatusses] = useState([]);
    const [alarms, setAlarms] = useState([]);

    const switchRoom = async (deviceid, devicename, location) => {
        const deviceData = await import("../data/devices.js");
        
        if (alarms.length === 0) {
            hideError();
            const data = await deviceData.updateDeviceField(deviceid, devicename, 'ObjectID', location);
        } else {
            showError(t('roomalarmserror'));
        }
    }

    const changeData = async (event) => {
        event.preventDefault();

        const deviceData = await import("../data/devices.js");
        const data = await deviceData.updateDeviceField(device.deviceid, device.devicename, 'Name', event.target.name.value);

        if(data === "OK" || data.length > 0){
            crudAlertControl('update', data);
        }

        switchRoom(device.deviceid, device.devicename, event.target.location.value);
    }

    const showError = (text) => {
        const errMsgElement = document.querySelector('.err_msg');
        errMsgElement.textContent = text;
        errMsgElement.style.display = 'block';
    }

    const hideError = () => {
        const errMsgElement = document.querySelector('.err_msg');
        errMsgElement.textContent = '';
        errMsgElement.style.display = 'none';
    }

    const getData = async () => {
        const [deviceData, objectsData] = await Promise.all([
            import("../data/devices.js"),
            import("../data/objects.js")
        ])

        const data = await deviceData.getDevices(props.router.params.id);
        const params = await deviceData.getDeviceConfig(props.router.params.id);
        const location = await objectsData.getObjects();
        const status = await deviceData.getTriggerStatus();

        setDevice(data[0]);
        setParameters(params);
        setLocations(location);
        setStatusses(status);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <React.Fragment>
            <div className='loc_content'>
                <form type="POST" onSubmit={(event) => changeData(event)}>
                    <input className="btn btn-save" style={{ marginTop: '0px' }} type="submit" name="submit" value={t('save')} />
                    <h1><button className="goBackBtn" onClick={() => navigate('/manage/devices/object/' + device.devicetypeid)}><i class="fas fa-arrow-circle-left"></i></button> {t('configuration')}</h1>

                    <div className="alert alert-success alert-update" role="alert">
                        {t('updatedevice')}
                    </div>

                    <div class="alert alert-danger" role="alert">
                        {t('somethingwrong')}
                    </div>

                    <div className='err_msg'></div>
                    <div className="deviceDetails">
                        <table className="table">
                            <tr className="roweven">
                                <td className="td">{t('name')}</td>
                                <td className="td"><input type="text" name="name" id="name" defaultValue={device.devicename} className="form-control" /></td>
                            </tr>
                            <tr className="roweven">
                            <td className="td" style={{ verticalAlign: 'top' }}>{t('location')}</td>
                                <td className="td">
                                    <ObjectLocation type="device" objectid={device.locationid} />
                                </td>
                            </tr>
                        </table>
                    </div>
                </form>

                <h2>{t('detailconfiguration')}</h2>

                <div className="deviceDetails">
                    <table className="table">
                        {parameters.map((params, i) => {
                            if (params.parametername === 'Trigger1') {
                                return (
                                    <tr>
                                        <td className="td">{params.parametername}</td>
                                        <td className="td">
                                            <select key={`${Math.floor((Math.random() * 1000))}-min`} name="status" onChange={(event) => { updateDeviceConfField(device.deviceid, device.devicename, params.parameterid, event.target.value) }} id="statusses" defaultValue={params.parametervalue} className="form-control">
                                                <option value='0'>{t('select')}</option>
                                                {statusses.map((status, i) => {
                                                    return (
                                                        <option value={status.statusid}>{status.name} ({status.statusid})</option>
                                                    )
                                                })}
                                            </select>
                                            <input type="hidden" name={`param${params.parameterid}`} id={`param${params.parameterid}`} defaultValue={params.parametervalue} />
                                        </td>
                                    </tr>
                                )
                            } else {
                                return (
                                    <tr>
                                        <td className="td">{params.parametername}</td>
                                        <td className="td">
                                            <input type="hidden" name={`param${params.parameterid}`} id={`param${params.parameterid}`} defaultValue={params.parametervalue} />
                                            <input type="text" onChange={(event) => { updateDeviceConfField(device.deviceid, device.devicename, params.parameterid, event.target.value) }} name={`param${params.parameterid}Val`} id={`param${params.parameterid}Val`} defaultValue={params.parametervalue} class="form-control" />
                                        </td>
                                    </tr>
                                )
                            }
                    })}
                    </table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(DeviceDetails);

import Config from "../config.json";

export const dashAlarms = (reportdate, employeeid) => {
    return fetch(Config.API_URL + `/mba/report/employeedash/alarms`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "reportdate": reportdate,
            "employeeid": employeeid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const employeeInfo = (employeeid) => {
    return fetch(Config.API_URL + `/mba/report/employeedash/employee`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('CustomerID'),
            "employeeid": employeeid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const dashLogins = (reportdate, employeeid) => {
    return fetch(Config.API_URL + `/mba/report/employeedash/logins`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('GUID'),
            "reportdate": reportdate,
            "employeeid": employeeid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}

export const dashLogging = (reportdate, employeeid) => {
    return fetch(Config.API_URL + `/mba/report/employeedash/logging`, {
        "method": "GET",
        "headers": {
            "Authorization": "Bearer " + localStorage.getItem('id_token'),
            "Content-Type": "application/x-www-form-urlencoded",
            "userid": localStorage.getItem('UserID'),
            "customerid": localStorage.getItem('GUID'),
            "reportdate": reportdate,
            "employeeid": employeeid
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
}